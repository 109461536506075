import React from 'react';

// Utils
import { useStyles } from './toolbar.styles';
import { useNavigate } from 'react-router-dom';
import useLayout from 'root-utils/hooks/use-layout';

// Components
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@bubotech/sumora-react-components/lib/button';
import Authentication from 'root-resources/oauth/authentication';
import AutoCompleteFilial from '../autocompletefilial/autocompletefilial';

// css
import './loader.css';

// Ícones
import AlterarEmpresa from '../../assets/icons/building-solid.svg';
import { Notifications, Person, Menu, Logout } from '@mui/icons-material';

export type MainToolbarPropType = {};

/**
 * Representa o header/toolbar do layout principal
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {MainToolbarPropType} props
 */
function MainToolbar(props: MainToolbarPropType): JSX.Element {
  const layout = useLayout();
  const navigate = useNavigate();
  const classes = useStyles(props);

  const { company, calculating, titleToolbar } = layout.reducer;

  const handleLogout = () => {
    Authentication.logout();
    navigate('/login');
  };

  return (
    <div className={classes.container}>
      {calculating && (
        <div className={classes.loading}>
          <div className='loader'>
            <div></div>
            <div></div>
            <div></div>
          </div>
          Calculando...
        </div>
      )}
      <div className={classes.containerLeftItem}>
        <Tooltip title={'Abrir/Fechar menu lateral'}>
          <div>
            <Button className={classes.buttonMenu} onClick={() => layout.actions.setMenuIsOpen()} disableElevation>
              <Menu color='inherit' />
            </Button>
          </div>
        </Tooltip>
        <div>
          <span className={classes.titleToolbar}>{titleToolbar}</span>
        </div>
      </div>

      <div className={classes.containerRightItem}>
        <Tooltip title={'Alterar Empresa'}>
          <IconButton className={classes.empresaContainer} onClick={() => navigate('/alterar-empresa')}>
            {company?.nmEmpresa!}
            <img src={AlterarEmpresa} alt='alterar-empresa' className={classes.iconEmpresa} />
          </IconButton>
        </Tooltip>
        <AutoCompleteFilial />
        <Tooltip title={'Notificações'}>
          <IconButton className={classes.icone}>
            <Notifications fontSize='inherit' />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Perfil'}>
          <IconButton onClick={() => navigate('/app/perfil')} className={classes.icone}>
            <Person fontSize='inherit' />
          </IconButton>
        </Tooltip>

        <Tooltip title={'Sair'}>
          <IconButton onClick={handleLogout} className={classes.icone}>
            <Logout fontSize='inherit' />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

export default MainToolbar;
