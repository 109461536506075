import React, { useEffect } from 'react'
import mermaid from 'mermaid'
import { Modal, Box } from '@mui/material'
import { useStyles } from './diagrama.styles'

mermaid.initialize({
  startOnLoad: true,
})

interface DiagramaPropsType {
  open: boolean
  onClose: () => void
  title: string
  subtitle: string
  data: string
}

const Diagrama = (props: { chart: string }) => {
  const { chart } = props

  useEffect(() => {
    mermaid.contentLoaded()
  }, [])

  return (
    <div className={'mermaid teste'}>{chart}</div>
  )
}

function ModalDiagrama(props: DiagramaPropsType): JSX.Element {
  const { open, onClose, title, subtitle, data } = props
  const classes = useStyles()

  useEffect(() => {
    mermaid.contentLoaded()
  }, [open])

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box className={classes.content}>
          <div className={classes.header}>
            <span>
              <b>{`${title} `}</b>
               - {subtitle}
            </span>
          </div>

          <div style={{ overflow: 'auto', height: '100%' }}>
            <div className={classes.diagram}>
              <Diagrama chart={`
                %%{init: {'theme': 'base', 'themeVariables': { 'primaryColor': '#EFF3F8', 'primaryBorderColor': '#C3D2E3' }}}%%
                graph TD;
                  ${data}
                `
              }/>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default ModalDiagrama