import React, { ElementType } from 'react';
import { useStyles } from './grid-card.styles';

interface GridCardIconProps {
  icon?: ElementType;
  path?: string;
}

function GridCardIcon(props: GridCardIconProps) {
  const classes = useStyles();
  const { icon: Icon, path } = props

  return Icon ? <Icon className={classes.icon}/> : <img src={path} alt='icon' className={classes.icon} />
}

export default GridCardIcon;