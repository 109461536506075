import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const toolbarHeight = 80;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: toolbarHeight,
    maxHeight: toolbarHeight,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingLeft: 10,
    paddingRight: 25,
    background: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      maxHeight: 180,
      paddingTop: 10,
      '& .MuiIconButton-root': {
      maxHeight: 32,
      padding: 4,
    },
    },
  },
  containerLeftItem: {
    display: 'flex',
    alignItems: 'center',
  },
  containerRightItem: {
    fontSize: 25,
    overflowX: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-start !important',
      marginLeft: 6,
      justifyContent: 'space-between',
      width: '100%'
    },
  },
  buttonMenu: {
    width: '24px !important',
    height: 24,
    minWidth: '35px !important',
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  titleToolbar: {
    marginLeft: 15,
    fontWeight: 'bold',
    fontFamily: 'Akrobat',
    fontSize: '24px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      marginLeft: 0,
    },
  },
  empresaContainer: {
    fontSize: '14px !important',
    maxHeight: 32,
    color: `${theme.palette.primary.main} !important`,
    border: `1px solid ${theme.palette.grey['100']} !important`,
    borderRadius: '6px !important',
    padding: '5px 12px !important',
    fontFamily: 'Open Sans !important',
    fontWeight: '600 !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  iconEmpresa: {
    marginLeft: 12,
  },
  icone: {
    fontSize: 'inherit !important',
    color: theme.palette.primary.main,
    padding: 4,
  },
  loading: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    height: `100vh`,
    marginBottom: 50,
    background: 'rgba(0, 0, 0, 0.6)',
    zIndex: 10000,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    fontSize: 14,
    fontWeight: 'bold',
    flexDirection: 'column',
  },
}));

export { useStyles };
