import { makeStyles } from '@mui/styles';

/**
 * Estilos do componente ImageSelector
 */
const useStyles = makeStyles((theme: any) => ({
  cardUpload: {
    height: 100,
    width: 100,
    fontWeight: 'bold',
    marginRight: 15,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.grey[300]}`,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#707070'
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  uploadFilesContainer: {
    position: 'absolute',
    zIndex: 2,
  },
  chooseImage: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    height: 100,
    width: 100,
    color: `${theme.palette.grey[300]}`,
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 1,
    left: 1
  },
  containerIcon: {
    color: `${theme.palette.grey[300]}`,
    cursor: 'pointer'
  },
  iconNameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    minWidth: 170,
    marginTop: 10
  },
  removeIcon: {
    cursor: 'pointer',
    color: '#707070'
  },
  suggestion: {
    fontSize: 11,
    color: '#707070'
  },
  whiteIcon: {
    filter: 'brightness(0) invert(1)',
  },
}));

export { useStyles };
