import { makeStyles } from '@mui/styles';

/**
 * Estilos do gráfico de pizza
 **/
const useStyles = makeStyles((theme: any) => ({
  legendasContainer: {
    flex: 2, 
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
    gridGap: '10px',
    overflow: 'auto',
    marginLeft: 30
  },
  legenda: {
    marginLeft: 8,
    fontSize: 14,
    color: '#777777',
    alignSelf: 'center'
  }
}));

export { useStyles };
