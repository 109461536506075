import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { Grid } from '@mui/material';
import { MainStateType } from 'root-states';
import { Home } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import { useStyles } from '../assets/style/alterar-empresa.styles';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import Authentication from 'root-resources/oauth/authentication';
import UsuarioAPI from '../resources/api-usuario';

import Empresa from 'root-models/empresa';
import Usuario from 'root-models/configuracoesdeacesso/usuario';

export type AlterarEmpresaPropType = {};



/**
 * View para alteração de empresa
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 */
function AlterarEmpresa(props: AlterarEmpresaPropType): JSX.Element {
  const classes = useStyles();
  const history = useNavigate();
  const [settingsIndex, setSettingsIndex] = useState<number>(-1);

  const usuarioAPI = new UsuarioAPI();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const currentCompany = useSelector<MainStateType, Empresa | undefined>((state) => state.appLayoutReducer?.company);
  const standardCompany = useSelector<MainStateType, Empresa | undefined>((state) => state.appLayoutReducer?.standardCompany);
  const oauth = new Authentication();
  const currentUser = useSelector<MainStateType, Usuario | null>((state) => state.appLayoutReducer?.currentUser);

  const [dataFiltered, setDataFiltered] = useState<Empresa[]>([]);

  const handleChangeCompany = (company: Empresa) => {
    oauth.changeEmpresa({
      empresa: company,
      usuario: currentUser || null
    }).then(() => {
      oauth.refreshToken().then(() => {
        appLayoutActions.setCompany(company);
        history('/dashboard');
      });
    });
  };

  const isSelected = (company: string) => {
    const nmEmpresa = currentCompany?.nmEmpresa ?? '';
    if (company.toLocaleUpperCase() === nmEmpresa.toLocaleUpperCase()) return classes.selectedCompany;

    return undefined;
  };
  useComponentDidMount(() => {
    loadData();
  });

  function loadData() {
    usuarioAPI.getEmpresaByUsuario().then((result) => {
      setDataFiltered(result.data);
    });

  }

  function handleChangeStandardCompany(index: number) {
    appLayoutActions.setStandardCompany(dataFiltered[index]);
    setSettingsIndex(-1);
  }

  function getLogo(dsCodigo: string) {
    return `https://s3.amazonaws.com/assets.dev.piani.net/logos/${dsCodigo}.png`;
  }

  const renderTornarPadrao = (index: number) =>
    index === settingsIndex ?
      <div className={classes.tornarPadrao} onClick={() => handleChangeStandardCompany(index)}>
        <Home />
        Tornar padrão
      </div>
      :
      <div className={classes.settingsIcon} style={{ cursor: 'default', height: '0px' }}>
        <MoreVert onClick={() => setSettingsIndex(index === settingsIndex ? -1 : index)}
        />
      </div>;

  return (
    <div className={classes.background}>
      <Grid container className={classes.mainContent}>
        {dataFiltered.map((empresa, index) =>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: 195 }}>
            {renderTornarPadrao(index)}
            <Grid
              key={empresa.idEmpresa}
              item
              xs={2}
              className={`${classes.companyContainer} ${isSelected(empresa.nmEmpresa ?? '')}`}
              onClick={() => handleChangeCompany({ ...empresa })}
            >
              <div>
                <img src={getLogo(empresa.dsCodigo || "")} alt={`logo-${empresa.nmEmpresa}`} className={classes.logo} />
              </div>
              <div className={classes.nameCompany}>
                {empresa.nmEmpresa}
              </div>
            </Grid>
            {standardCompany?.nmEmpresa === empresa.nmEmpresa ? <Home color='secondary' /> : <></>}
          </div>
        )}
      </Grid>
    </div>
  );
}

export default AlterarEmpresa;