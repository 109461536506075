import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material';
import { useStyles } from './graficobarras.styles';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface Data {
  data: number[];
  backgroundColor?: string;
  backgroundColorHorizontal?: string[];
  label: string;
  barPercentage?: number;
}

export type GraficoBarrasPropType = {
  labels: string[];
  datasets: Data[];
  tamanhoGrafico: number;
  esconderComparacao?: boolean;
  eixo?: 'horizontal' | 'vertical';
};

function GraficoBarras(props: GraficoBarrasPropType): JSX.Element {
  const { labels, datasets, tamanhoGrafico, esconderComparacao = false, eixo = 'vertical' } = props;
  const [actualData, setActualData] = useState<number>(0);
  const [compData, setCompData] = useState<number>(0);
  const [mostrarComparacao, setMostrarComparacao] = useState<boolean>(false);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box maxHeight={tamanhoGrafico} height={'100%'} width={'100%'} onMouseLeave={() => setMostrarComparacao(false)}>
      <Bar
        options={{
          indexAxis: eixo === 'vertical' ? 'x' : 'y',
          responsive: true,
          plugins: {
            legend: {
              display: Boolean(eixo === 'vertical'),
              position: eixo === 'vertical' ? 'bottom' : 'left',
              align: 'center',
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title: function (this, tooltipItem) {
                  setActualData(datasets[0].data[tooltipItem[0].dataIndex] / 1000);
                  datasets.length > 1 && setCompData(datasets[1].data[tooltipItem[0].dataIndex] / 1000);
                  setMostrarComparacao(true);
                  return '';
                },
              }
            },
            title: {
              display: false,
            },
          }
        }}
        style={{ maxHeight: tamanhoGrafico, paddingTop: 15, height: '100%' }}
        data={{ labels, datasets }}
      />
      {mostrarComparacao && !esconderComparacao && (
        <div className={classes.comparacao}>
          <Box display='flex' marginBottom={'8px'}>
            <p className={classes.comparacaoTitulo} style={{ color: theme.palette.primary.main }}>{actualData}k&nbsp;</p>
            <p className={classes.comparacaoTitulo}> vs {compData}k</p>
          </Box>
          <Box display='flex' alignItems={'center'} justifyContent={'flex-end'} width={'100%'}>
            <p
              className={classes.comparacaoTitulo}
              style={{ color: theme.palette.grey['500'] }}
            >
              TM {actualData / compData}
            </p>
          </Box>
        </div>
      )}
    </Box>
  );
}

export default GraficoBarras;