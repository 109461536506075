/**
 * Extrai o valor do campo do item
 * @param fieldName nome do atributo
 * @param item objeto para extrar o valor
 * @returns 
 */
export function getItemValue<T>(fieldName: string, item: T) {
  const fields = fieldName.split('.');

  let current: any = item;
  while (fields.length) {
    if (typeof current !== 'object' || !current) return undefined;
    else current = current[fields.shift() as keyof T || '' as keyof T];
  }

  return current;
}