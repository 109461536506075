import { makeStyles } from '@mui/styles';

/**
 * Estilos do componente de privilegio
 **/
const useStyles = makeStyles((theme: any) => ({
  modulo: {
    borderBottom: `2px solid ${theme.palette.grey['100']}`,
    borderTop: `1px solid ${theme.palette.grey['100']}`,
    backgroundColor: 'rgb(195, 210, 227, 0.25)',
  },
  privilegio: {
    height: '50px',
    padding: '16px 8px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
  },
  titulo: {
    color: `${theme.palette.grey['300']}`,
    fontWeight: 600
  },
  label: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    alignSelf: 'center',
    marginLeft: '3px'
  },
  switchContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}));

export { useStyles };
