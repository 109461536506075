import Swal from 'root-components/swal/swal';
import { SweetAlertIcon } from 'sweetalert2';

export type verifyUnsavedDataProps = {
    dirty: boolean,
    icon?: SweetAlertIcon,
    title?: string,
    message?: string,
    confirmButtonText?: string,
    cancelButtonText?: string,
    showCancelButton?: boolean,
    showConfirmButton?: boolean;
};

/**
 * Função que recebe o formulário e verifica se há dados não salvos ao sair da aba, disparando um swal para confirmar a ação.
 * @param {boolean} dirty Atributo dirty do Formik.
 * @param {SweetAlertIcon} icon Ícone do swal.
 * @param {string} title Título do swal.
 * @param {string} message Mensagem do swal.
 * @param {string} confirmButtonText Texto do botão de confirmação do swal.
 * @param {string} cancelButtonText Texto do botão de cancelamento do swal.
 * @param {boolean} showCancelButton Se o botão de cancelamento deve ser exibido.
 * @param {boolean} showConfirmButton Se o botão de confirmação deve ser exibido.
 * @returns {Promise<boolean>} Retorna uma promise com o resultado da ação, sendo true para salvar e false para sair sem salvar.
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 */
export default function verifyUnsavedData(props: verifyUnsavedDataProps): Promise<boolean> {
    const { dirty, icon, title, message, confirmButtonText, cancelButtonText, showCancelButton, showConfirmButton } = props;
    return new Promise<boolean>((resolve) => {
        if (dirty) {
            Swal({
                icon: icon || 'warning',
                title: title || 'Você tem dados não salvos',
                text: message || 'Tem certeza que deseja sair sem salvar?',
                showCancelButton: showCancelButton || true,
                showConfirmButton: showConfirmButton || true,
                cancelButtonText: cancelButtonText || 'Sair',
                confirmButtonText: confirmButtonText || 'Salvar',
            }).then(result => {
                if (result.isConfirmed) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(() => { });
        } else {
            resolve(false);
        }
    });
}