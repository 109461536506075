import React, { useEffect, useState } from 'react';
import { useStyles } from './style';
import Arquivo from 'root-models/arquivo';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import { Clear } from '@mui/icons-material';
import IconUploadSolid from 'root-icons/icon-upload-solid.svg';
import { UploadFiles } from '@bubotech/sumora-react-components';
import { ContextStateType } from 'root-states/reducers/context-reducer';
import useVerificaDados from 'root-components/alertadadosnaosalvos/hooks/useVerificaDados';

export type ImageSelectorProdutoPropType = {
  imageChange: (image?: Arquivo) => void;
  arquivoURL?: Arquivo;
};
/**
 * Icon Upload Component
 *
 * @author Carlos Bageston <carlos.bageston@bubo.tech>
 * @param {ImageSelectorProdutoPropType} props
 */
export default function ImageSelector(props: ImageSelectorProdutoPropType) {
  const classes = useStyles();
  const [arquivo, setArquivo] = useState<Arquivo>();
  const [imageSelected, setImageSelected] = useState<boolean>(false);
  const [style, setStyle] = useState<React.CSSProperties | undefined>(undefined);
  const { arquivoURL, imageChange } = props;

  const contextProps = useSelector<MainStateType, ContextStateType>(state => state.contextReducer);
  useVerificaDados({ funcaoPrincipalProps: { dirty: contextProps.principalTabHasUnsavedData }, handleSubmit: contextProps.principalFormSubmit, ignoreTabChange: true });

  const handleChange = (fileList: Arquivo[]) => {
    setArquivo(fileList[0]);
    setImageSelected(true);
    imageChange(fileList[0]);
  };

  const handleRemove = () => {
    setArquivo(undefined);
    setImageSelected(false);
    imageChange(undefined);
  }

  useEffect(() => {
    if (arquivoURL) setImageSelected(true);
  }, [arquivoURL]);

  const showImage = (arquivoURL && !arquivoURL.dsBase64 && arquivoURL.dsArquivo) || arquivo?.dsFile;

  const getImagePath = () => {
    if (arquivoURL?.dsArquivo) return arquivoURL.dsArquivo;
    else return arquivo?.dsFile;
  }

  const handleMouseOver = () => {
    showImage && setStyle({ backgroundColor: 'rgba(0,0,0,0.4)' });
  }

  const handleMouseLeave = () => {
    setStyle(undefined);
  }

  const fileName = arquivo?.dsFileName || arquivoURL?.dsFileName;

  return (
    <section id='dados-imagem'>
      <div className={classes.imageContainer}>
        <div className={classes.uploadFilesContainer} >
          <UploadFiles
            propsInput={{
              accept: '.png, .jpg, .jpeg, .ico'
            }}
            multiUploads={false}
            key={1}
            addNewFiles={handleChange}
            tratarError={() => { }}
          >
            <div className={classes.chooseImage} style={style} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
              {showImage &&
                <div className={classes.containerIcon} style={{ display: style ? 'block' : 'none' }}>
                  <img src={IconUploadSolid} className={classes.whiteIcon} alt='Ícone de seleção de imagem' />
                </div>
              }
              {!imageSelected &&
                <>
                  <div className={classes.containerIcon}>
                    <img src={IconUploadSolid} alt='Ícone de seleção de imagem' />
                  </div>
                  <p>
                    Selecionar Icone
                  </p>
                </>
              }
            </div>
          </UploadFiles>

          <div className={classes.iconNameContainer}>
            {fileName ? fileName : <p className={classes.suggestion}>*Sugestão de imagem 864x864</p>}
            {fileName && <Clear className={classes.removeIcon} onClick={handleRemove} />}
          </div>
        </div>
        <div className={classes.cardUpload}>
          {showImage && <img src={getImagePath()} alt='ícone' style={{ maxHeight: 100, maxWidth: 100 }} />}
        </div>
      </div>
    </section>
  );
}