import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import ContextActions from 'root-states/actions/context-actions';
import { ContextStateType } from 'root-states/reducers/context-reducer';
import verifyUnsavedData, { verifyUnsavedDataProps } from '../utils/verifica-dados';
import { useNavigate } from 'react-router-dom';


export type verificarDadosProps = {
    funcaoPrincipalProps: verifyUnsavedDataProps,
    handleSubmit: Function;
    ignoreTabChange?: boolean;
};

/**
 * Hook para verificar se os dados foram salvos antes de mudar de aba ou sair da página
 * @param {verifyUnsavedDataProps} funcaoPrincipalProps Propriedades da função principal de verificação de dados
 * @param {Function} handleSubmit função que dispara a submissão do formulário
 * @param {boolean} ignoreTabChange se true, não valida a mudança de aba
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 */
const useVerificaDados = (props: verificarDadosProps) => {
    const { funcaoPrincipalProps, handleSubmit, ignoreTabChange } = props;

    const contextProps = useSelector<MainStateType, ContextStateType>(state => state.contextReducer);
    const contextActions = new ContextActions(useDispatch<Dispatch<DispatchAction>>());

    const history = useNavigate();

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = true;
    };

    // Dispara o bloqueio de mudança de aba e o listener para quando o usuário tentar sair da página com dados não salvos
    useEffect(() => {
        if (funcaoPrincipalProps.dirty || contextProps.principalTabHasUnsavedData) {
            if (!ignoreTabChange && funcaoPrincipalProps.dirty) {
                contextActions.allowTabChange(false);
            }
            contextActions.allowRouteChange(false);
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            contextActions.tryChangeRoute("");
            contextActions.allowTabChange(true);
            contextActions.allowRouteChange(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funcaoPrincipalProps.dirty, contextProps.principalTabHasUnsavedData]);

    // Impede que o usuário mude de aba sem saber que os dados não foram salvos
    useEffect(() => {
        if (!ignoreTabChange)
            if (funcaoPrincipalProps.dirty && contextProps.tabRequested !== undefined && contextProps.tabRequested !== contextProps.selectedTab) {
                verifyUnsavedData(funcaoPrincipalProps).then(res => {
                    if (res) {
                        handleSubmit();
                        // tabRequested rollback
                        contextActions.tryChangeTab(contextProps.selectedTab);
                    } else if (res === false) {
                        window.removeEventListener('beforeunload', handleBeforeUnload);
                        contextActions.allowTabChange(true);
                        if (contextProps.tabRequested !== contextProps.selectedTab && contextProps.tabRequested !== undefined)
                            contextActions.changeTab(contextProps.tabRequested);
                    }
                });
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextProps.tabRequested]);

    // Impede que o usuário mude de rota sem saber que os dados não foram salvos
    useEffect(() => {
        if ((funcaoPrincipalProps.dirty || contextProps.principalTabHasUnsavedData) && (contextProps.routeRequested !== contextProps.selectedRoute)) {
            verifyUnsavedData(contextProps.principalTabHasUnsavedData ? { ...funcaoPrincipalProps, dirty: contextProps.principalTabHasUnsavedData } : funcaoPrincipalProps).then(res => {
                if (res) {
                    funcaoPrincipalProps.dirty && handleSubmit();
                    // routeRequested rollback
                    contextActions.tryChangeRoute(contextProps.selectedRoute);
                } else if (res === false) {
                    window.removeEventListener('beforeunload', handleBeforeUnload);
                    contextActions.allowRouteChange(true);
                    contextActions.changeRoute(contextProps.routeRequested);
                    history(contextProps.routeRequested);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextProps.routeRequested]);
};

export default useVerificaDados;