import React, { useState } from 'react';
import { Line } from "react-chartjs-2";
import { Box, useTheme } from '@mui/material';
import { useStyles } from './graficolinha.styles';
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export type Data = {
  data: number[]
  backgroundColor: string
  label?: string
  borderWidth?: number
  borderColor?: string
  fill?: boolean
  pointRadius?: number
  borderDash?: number[]
  borderCapStyle?: "butt" | "round" | "square"
}

export type GraficoLinhaPropType = {
  labels: string[]
  datasets: Data[]
  tamanhoGrafico: number
  mostrarLegenda?: boolean
  alinhamentoLegenda?: 'start' | 'center' | 'end'
  posicaoLegenda?: 'bottom' | 'center' | 'chartArea' | 'left' | 'right' | 'top'
  tituloComparacao?: string
  posicaoTituloComparacao?: 'center' | 'flex-start' | 'flex-end'
  onHover?: (tooltipItem: any) => void
  legendaComparacao?: string[]
  corDestaque?: string
  iconComparacao?: JSX.Element
  valorAtual?: string
  valorComparado?: string
  destacarSomenteValorComparado?: boolean
  destacarTitulo?: boolean
}

/**
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param labels Lista de Labels do gráfico 
 * @param datasets Lista de dados do gráfico
 * @param tamanhoGrafico Altura do gráfico
 * @param mostrarLegenda Mostrar legenda do gráfico
 * @param alinhamentoLegenda Alinhamento da legenda em relação ao gráfico (start, center, end)
 * @param posicaoLegenda Posição da legenda em relação ao gráfico (bottom, center, chartArea, left, right, top)
 * @param tituloComparacao Título do pop up de comparação
 * @param setTituloComparacao Função para alterar o título do popup
 * @param legendaComparacao Legenda do pop de comparação
 * @param corDestaque Cor destaque do popup
 * @param iconComparacao Ícone da comparação
 * @param valorAtual Valor atual da comparação (esquerda)
 * @param valorComparado Valor comparado (direita)
 * @param destacarSomenteValorComparado Caso true aplica a cor destaque somente no valor comparado
 */
function GraficoLinha(props: GraficoLinhaPropType): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();

  const { 
    datasets, 
    labels, 
    tamanhoGrafico, 
    mostrarLegenda = false, 
    alinhamentoLegenda = 'center', 
    posicaoLegenda = 'bottom',
    tituloComparacao,
    legendaComparacao,
    corDestaque,
    iconComparacao,
    valorAtual,
    valorComparado,
    onHover,
    destacarSomenteValorComparado = true,
    posicaoTituloComparacao = 'center',
    destacarTitulo = false
  } = props;
  
  const [posicaoMouse, setPosicaoMouse] = useState<number>(0);
  const [mostrarComparacao, setMostrarComparacao] = useState<boolean>(false);

  return (
    <Box height={tamanhoGrafico} width={'100%'} onMouseLeave={() => setMostrarComparacao(false)}>
      <Line 
        options={{
          responsive: true,
          normalized: true,
          plugins: {
            legend: {
              display: mostrarLegenda,
              align: alinhamentoLegenda,
              position: posicaoLegenda,
              labels: {
                boxHeight: 1
              }
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title: function(this, tooltipItem) {
                  onHover && onHover(tooltipItem);
                  const position: number = tooltipItem[0].parsed.x * 10;
                  if (position >= 80) setPosicaoMouse(80);
                  else setPosicaoMouse(position);
                  setMostrarComparacao(true);
                  return '';
                },
              }
            },
            title: {
              display: false
            }
          }
        }}
        style={{ maxHeight: tamanhoGrafico }}
        data={{ labels, datasets }}
      />
      {mostrarComparacao && (
        <div className={classes.comparacao} style={{ left: `${posicaoMouse}%` }}>
          <Box display='flex' justifyContent={posicaoTituloComparacao}>
            <p className={classes.comparacaoTitulo} style={{ color: destacarTitulo ? corDestaque : theme.palette.primary.main }}>{tituloComparacao}</p>
          </Box>
          {legendaComparacao && (
            <Box display='flex'>
              <p className={classes.legenda}>{legendaComparacao[0]}</p>
              <p className={classes.legenda} style={{ color: corDestaque }}>&nbsp; vs &nbsp;</p>
              <p className={classes.legenda}>{legendaComparacao[1]}</p>
            </Box>
          )}
          <Box display='flex' alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <p className={classes.comparacaoTitulo} style={{ color: !destacarSomenteValorComparado ? corDestaque : theme.palette.primary.main }}>{valorAtual}</p>
            <Box display='flex'>
              {iconComparacao && iconComparacao}
              {valorComparado && (
                <p className={classes.comparacaoTitulo} style={{ color: corDestaque }}>
                  {valorComparado}
                </p>
              )}
            </Box>
          </Box>
        </div>
      )}
    </Box>
  )
}

export default GraficoLinha;
