import { useState, useRef, useEffect, useCallback } from 'react';

import Swal from '../swal/swal';
import Filial from 'root-models/cadastros/filial/filial';

import { IconButton, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Utils
import { useStylesFilial } from './filial.style';
import useLayout from 'root-utils/hooks/use-layout';
import FilialAPI from 'root-resources/api/cadastros/filial/filial';

const api = new FilialAPI();

function AutoCompleteFilial() {
  const classe = useStylesFilial();
  const containerRef = useRef<HTMLButtonElement | null>(null);

  const layout = useLayout();
  const { currentBranch } = layout.reducer;

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [branchOptions, setBranchOptions] = useState<Filial[]>([]);

  const handleItemClick = (filial: Filial) => {
    layout.actions.changeFilial(filial);
    setShowOptions(false);
  };

  const fetchBranchOptions = () => {
    layout.actions.setLoading(true);

    api
      .findByPage({ page: 1, orderField: 'nmFantasia', orderType: 'ASC', staticParams: ',stEmpresa:1' })
      .then((res) => {
        setBranchOptions(res.data.data as Filial[]);
        setShowOptions(true);
      })
      .catch((err) => {
        setBranchOptions([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar Filiais',
          icon: 'error',
        });
      })
      .finally(() => layout.actions.setLoading(false));
  };

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <>
      <Tooltip title={'Alterar Filial'}>
        <span>
          <IconButton className={classe.filialContainer} ref={containerRef} onClick={() => fetchBranchOptions()}>
            <KeyboardArrowUpIcon
              color='inherit'
              style={
                showOptions
                  ? {
                      transform: showOptions ? 'rotate(180deg)' : undefined,
                      transition: 'transform 0.3s linear',
                      marginRight: '6px',
                    }
                  : {
                      marginRight: '6px',
                    }
              }
            />
            <span className={classe.label}>{currentBranch?.sgEmpresa}</span>
          </IconButton>
        </span>
      </Tooltip>
      {showOptions && (
        <div className={classe.containerList}>
          <ul>
            {branchOptions.map((filial, index) => (
              <li key={index} className={classe.linhaList} onClick={() => handleItemClick(filial)}>
                {`${filial.nmFantasia} - ${filial.sgEmpresa}`}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default AutoCompleteFilial;
