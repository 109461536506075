import { makeStyles } from '@mui/styles';

/**
 * Estilos do GridCard
 **/
const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '12px 8px',
    borderRadius: 12,
    gap: 10,
    height: 85,
    display: 'flex',
    background: theme.palette.primary.main
  },
  title: {
    fontWeight: 700,
    color: theme.palette.common.white
  },
  text: {
    color: theme.palette.common.white,
    textOverflow: 'ellipsis'
  },
  icon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    color: theme.palette.common.white,
    height: '50px !important',
    width: '50px !important',
    bottom: 10,
    opacity: 0.5,
  },
  percentageContainer: {
    height: '60px !important',
    width: '60px !important',
    opacity: 0.8,
    alignSelf: 'flex-end',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    border: `3px solid ${theme.palette.common.white}`,
    borderRadius: 50,
    justifyContent: 'center',
    color: theme.palette.common.white
  }
}));

export { useStyles };
