import { ReactNode } from 'react';
import { GridProps, Grid } from '@mui/material';
import { useStyles } from './grid-card.styles';

interface GridCardRootProps extends GridProps {
  children: ReactNode;
  background?: string;
}

/**
 * Componente para Cards usados em grids
 *
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @param props
 * @returns
 */
function GridCardRoot(props: GridCardRootProps): JSX.Element {
  const classes = useStyles();
  const { children, background, ...rest } = props;

  return (
    <Grid
      item
      flexDirection={'column'}
      justifyContent={'space-between'}
      className={classes.root}
      {...rest}
      style={{ backgroundColor: background }}
    >
      {children}
    </Grid>
  );
}

export default GridCardRoot;
