import React from 'react';

import iconPCP from 'root-icons/icon-pcp.svg';
import iconFiscal from 'root-icons/icon-fiscal.svg';
import iconEstoque from 'root-icons/icon-estoque.svg';
import iconCadastro from 'root-icons/icon-cadastro.svg';
import iconDasboard from 'root-icons/icon-dashboard.svg';
import iconComercial from 'root-icons/icon-comercial.svg';
import iconModuloPrecos from 'root-icons/modulo-precos.svg';
import iconFaturamento from 'root-icons/icon-faturamento.svg';
import iconModuloLogistica from 'root-icons/icon-logistica.svg';
import iconConfigAccess from 'root-icons/icon-config-access.svg';
import iconQuestionario from 'root-icons/icon-modulo-questionario.svg';
import iconModuloOrdemServico from 'root-icons/icon-modulo-ordem-servico.svg';
import iconFinanceiro from 'root-icons/icon-modulo-financeiro.svg';
import iconCompra from 'root-icons/icon-modulo-compra.svg';

/**
 * Modelo das definições de um item do menu lateral
 *
 * @author Marcos Davi <marcos.davis@kepha.com.br>
 */
export interface MenuLateralItemModel {
  label: string | JSX.Element;
  Icon?: (props: any) => JSX.Element;
  route?: string;
  childrens?: MenuLateralItemModel[];
  childrensBackgroundColor?: LevelColors;
  showProd?: boolean;
}

/**
 * Enum de cores de fundo dos itens do menu pra cada nível.
 * O primeiro nível são os filhos do primeiro item.
 */
export enum LevelColors {
  FIRST_LEVEL = '#3252A8',
  SECOND_LEVEL = '#5C78C7',
  THIRD_LEVEL = '#8DA5E8',
}

const itensMenuLateralDev: MenuLateralItemModel[] = [
  {
    label: 'Dashboard',
    Icon: (props) => (
      <img src={iconDasboard} alt='ícone-dashboard' {...props} />
    ),
    route: '/dashboard',
    showProd: true,
  },
  {
    label: 'Cadastros',
    Icon: (props) => (
      <img src={iconCadastro} alt='ícone-cadastros' {...props} />
    ),
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    showProd: true,
    childrens: [
      {
        label: 'Empresa',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          { label: 'Empresa', route: '/cadastros/empresa' },
          { label: 'Filial', route: '/cadastros/filial' }
        ]
      },
      {
        label: 'Pessoas',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          {
            label: 'Cadastros',
            childrensBackgroundColor: LevelColors.THIRD_LEVEL,
            childrens: [
              { label: 'Estrutura Organizacional', route: '/cadastros/pessoas/estrutura-organizacional' },
              { label: 'Departamento', route: '/cadastros/pessoas/departamento' },
              { label: 'Setor', route: '/cadastros/pessoas/setor' },
            ]
          },
          { label: 'Cliente', route: '/cadastros/pessoas/cliente' },
          { label: 'Fornecedor', route: '/cadastros/pessoas/fornecedor' },
          { label: 'Representante', route: '/cadastros/pessoas/representante' },
          { label: 'Colaborador', route: '/cadastros/pessoas/colaborador' },
          { label: 'Técnico', route: '/cadastros/pessoas/tecnico' },
          { label: 'Vendedor', route: '/cadastros/pessoas/vendedor' }
        ],
      },
      {
        label: 'Produto',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          {
            label: 'Cadastros',
            childrensBackgroundColor: LevelColors.THIRD_LEVEL,
            childrens: [
              { label: 'Cor', route: '/cadastros/produto/corproduto' },
              { label: 'Etapa', route: '/cadastros/produto/etapa' },
              { label: 'Marca', route: '/cadastros/produto/marca' },
              { label: 'Unidade de Medida', route: '/cadastros/produto/unidademedida' },
            ]
          },
          {
            label: 'Estrutura Mercadológica',
            childrensBackgroundColor: LevelColors.THIRD_LEVEL,
            childrens: [
              { label: 'Grupo', route: '/cadastros/produto/estrutura-mercadologica/grupo' },
              { label: 'Sub-grupo', route: '/cadastros/produto/estrutura-mercadologica/subgrupo' },
              { label: 'Seção', route: '/cadastros/produto/estrutura-mercadologica/secao' },
              { label: 'Divisão', route: '/cadastros/produto/estrutura-mercadologica/divisao' }
            ]
          },
          {
            label: 'Ficha Técnica',
            childrensBackgroundColor: LevelColors.THIRD_LEVEL,
            childrens: [
              { label: "Ficha técnica", route: '/cadastros/ficha-tecnica' },
              {
                label: "Nutricional",
                childrensBackgroundColor: LevelColors.THIRD_LEVEL,
                childrens: [
                  { label: "Categoria", route: '/cadastros/categoria-nutricional' },
                  { label: "Referência", route: '/cadastros/referencia-nutricional' },
                ]
              }
            ]
          },
          { label: 'Produto', route: '/cadastros/produto/produto' },
        ],
      },
    ],
  },
  {
    label: 'PCP',
    Icon: (props: any) => <img src={iconPCP} alt='ícone-pcp' {...props} />,
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    showProd: true,
    childrens: [
      {
        label: 'Dashboard',
        route: '/modulo-pcp/dashboard-ordem-producao',
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Cadastros',
        childrens: [
          {
            childrensBackgroundColor: LevelColors.SECOND_LEVEL,
            label: 'Mão de obra',
            childrens: [
              { label: 'Turno de Trabalho', route: '/modulo-pcp/turno' },
              {
                label: 'Jornada de Trabalho',
                route: '/modulo-pcp/jornada-trabalho',
              },
              { label: 'Setor', route: '/modulo-pcp/setor' },
            ],
          }
        ],
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Ordem de Produção',
        childrens: [
          {
            label: 'Cadastros',
            childrens: [
              { label: 'Plantas e linhas de produção', route: '/modulo-pcp/planta-linha-producao' },
              { label: 'Maquinário', route: '/modulo-pcp/maquinario' },
              { label: 'Tipo de Cancelamento', route: '/modulo-pcp/tipo-cancelamento' },
              { label: 'Categoria de não conformidade', route: '/modulo-pcp/categoria-nao-conformidade' },
              { label: 'Tipo de não conformidade', route: '/modulo-pcp/tipo-nao-conformidade' },
              { label: 'Ciclo de Produção', route: '/modulo-pcp/ciclo-producao' }
            ]
          },
          { label: 'Lote de Produção', route: '/modulo-pcp/lote-producao' },
          { label: 'Ordem de Produção', route: '/modulo-pcp/ordem-producao' },
          { label: 'Ordem de Produção em Lotes', route: '/modulo-pcp/lote-ordem-producao' },
          { label: 'Programação de Produção', route: '/modulo-pcp/programacao-producao' },
          { label: 'Apontamento', route: '/modulo-pcp/apontamentos' },
          { label: 'Não conformidades', route: '/modulo-pcp/nao-conformidade' },
          { label: 'Pedido de insumos', route: '/modulo-pcp/insumo-ordem-producao' },
        ]
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Cancelamento',
        childrens: [
          { label: 'Cancelar Apontamento', route: '/modulo-pcp/cancelamento-apontamento-ordem-producao' },
          { label: 'Cancelar Ordem de Produção', route: '/modulo-pcp/cancelamento-ordem-producao' },
        ]
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Gestão',
        childrens: [
          {
            label: 'Cadastros',
            childrens: [
              {
                label: 'Lote Econômico',
                route: '/modulo-pcp/lote-economico-producao'
              },
              {
                label: 'Perfil de Prioridade',
                route: '/modulo-pcp/perfil-prioridade-producao',
              },
              {
                label: 'Prioridades',
                route: '/modulo-pcp/prioridade-de-ordem-de-producao',
              },
            ],
          },
          {
            label: 'Calendário de Produção',
            route: '/modulo-pcp/calendario-producao'
          },
          {
            label: 'Gestão de Produção',
            route: '/modulo-pcp/gestao-producao',
          },
        ],
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Qualidade',
        childrens: [
          {
            label: 'Cadastros',
            childrens: [
              {
                label: 'Alérgenos',
                route: '/modulo-pcp/alergenos',
              },
              {
                label: 'Tipo de Característica de Qualidade',
                route: '/modulo-pcp/tipo-caracteristica',
              },
              {
                label: 'Característica Qualidade',
                route: '/modulo-pcp/caracteristica-qualidade',
              },
              {
                label: 'Tipo de Teste',
                route: '/modulo-pcp/tipo-teste-inspecao-qualidade',
              },
              {
                label: 'Especificação de Análise',
                route: '/modulo-pcp/especificacao-de-analise-para-inspecao-e-qualidade',
              },
              {
                label: 'Inspeção e Qualidade',
                route: '/modulo-pcp/analises-para-inspecao-e-qualidade',
              },
            ],
          },
          {
            label: 'Inspeção e Qualidade',
            route: '/modulo-pcp/inspecao-qualidade',
          },
          {
            label: 'Laudo de Qualidade',
            route: '/modulo-pcp/laudos-qualidade',
          },
        ],
      },
    ],
  },
  {
    label: 'Estoque',
    Icon: (props) => (
      <img src={iconEstoque} alt='ícone-módulo-estoque' {...props} />
    ),
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    showProd: true,
    childrens: [
      {
        label: 'Dashboard',
        route: '/modulo-estoque/dashboard-movimentacao-item',
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Cadastros',
        childrens: [
          { label: 'Armazém', route: '/modulo-estoque/armazem' },
          { label: 'Estoque', route: '/modulo-estoque/estoque' },
          { label: 'Endereçamento de Estoque', route: '/modulo-estoque/enderecamento-estoque' },
        ]
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Movimentação',
        childrens: [
          { label: 'Consulta de Saldo', route: '/modulo-estoque/consulta-saldo' },
          { label: 'Extrato de Movimentação', route: '/modulo-estoque/extrato-movimentacao' },
          { label: 'Lançamento Avulso', route: '/modulo-estoque/lancamento-avulso-estoque' },
          {
            label: 'Transferência de Itens',
            route: '/modulo-estoque/transferencia-itens-estoque',
          },
        ]
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Balanços',
        childrens: [
          { label: 'Balanço', route: '/modulo-estoque/balanco' },
          { label: 'Contagem de Estoque', route: '/modulo-estoque/contagem-estoque' },
        ]
      },
      { label: 'Configuração', route: '/modulo-estoque/configuracao-estoque' },
    ],
  },
  {
    label: 'Compras',
    Icon: (props) => (
      <img src={iconCompra} alt='ícone-módulo-compra' {...props} />
    ),
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    showProd: true,
    childrens: [
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Cadastros',
        childrens: [
          {
            label: 'Orçamento',
            route: '/modulo-compra/orcamento',
          },
          {
            label: 'Centro de Resultados',
            route: '/modulo-compra/centro-resultados',
          },
          {
            label: 'Finalidade da Compras',
            route: '/modulo-compra/finalidade-compra',
          },
          {
            label: 'Local de Entregas',
            route: '/modulo-compra/local-entrega',
          },
        ]
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Aprovação',
        childrens: [
          {
            label: 'Solicitação de Compras',
            route: '/modulo-compra/aprovar-solicitacao-compra',
          },
          {
            label: 'Pedido de Compra',
            route: '/modulo-compra/aprovacao-pedido-compra'
          }
        ]
      },
      {
        label: 'Solicitação de Compra',
        route: '/modulo-compra/solicitacao-compra',
      },
      {
        label: 'Cotação de Compras',
        route: '/modulo-compra/cotacao-compras'
      },
      {
        label: 'Pedidos de Compra',
        route: '/modulo-compra/pedidos-compra',
      }
    ],
  },
  {
    label: 'Logistica',
    Icon: (props) => (
      <img src={iconModuloLogistica} alt='ícone-módulo-logistica' {...props} />
    ),
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    showProd: true,
    childrens: [
      {
        label: 'Recepção de Mercadoria',
        route: '/modulo-logistica/recepcao-mercadoria',
      },
    ],
  },
  {
    label: 'Faturamento',
    Icon: (props) => (
      <img src={iconFaturamento} alt='ícone-faturamento' {...props} />
    ),
    showProd: true,
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      {
        label: 'Formas de Pagamento',
        route: '/modulo-faturamento/forma-pagamento',
      },
      {
        label: 'Condições de Pagamento',
        route: '/modulo-faturamento/condicao-pagamento',
      },
      {
        label: 'Formas X Condições',
        route: '/modulo-faturamento/forma-pagamento-condicao-pagamento',
      },
    ],
  },
  {
    label: 'Ordem de Serviço',
    Icon: (props) => (
      <img src={iconModuloOrdemServico} alt='ícone-ordem-serviço' {...props} />
    ),
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      {
        label: 'Dashboard',
        route: '/modulo-ordem-servico/dashboard',
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Cadastros',
        childrens: [
          {
            label: 'Ação Realizada',
            route: '/modulo-ordem-servico/acao-realizada',
          },
          {
            label: 'Serviço Prestado',
            route: '/modulo-ordem-servico/servico-prestado',
          },
          {
            label: 'Tipo de Serviço',
            route: '/modulo-ordem-servico/tipo-servico-prestado',
          },
        ]
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Agenda',
        childrens: [
          {
            label: 'Agenda de Serviços',
            route: '/modulo-ordem-servico/agenda-ordem-servico',
          },
          {
            label: 'Agendamento',
            route: '/modulo-ordem-servico/agendamento-servico',
          },
        ]
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Solicitação',
        childrens: [
          {
            label: 'Autorização',
            route: '/modulo-ordem-servico/autorizacao-ordem-servico',
          },
          {
            label: 'Solicitação',
            route: '/modulo-ordem-servico/solicitacao-ordem-servico',
          },
        ]
      },
      {
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        label: 'Configuração',
        childrens: [
          {
            label: 'Questionário',
            route: '/modulo-ordem-servico/configuracao-questionario-os',
          },
        ]
      },
    ],
  },
  {
    label: 'Questionários',
    Icon: (props) => <img src={iconQuestionario} alt='Ícone-questionários' {...props} />,
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      { label: 'Questionário', route: '/questionarios/questionario' },
      { label: 'Respostas', route: '/questionarios/respostas' }
    ]
  },
  {
    label: 'Vendas',
    Icon: (props) => <img src={iconComercial} alt='ícone-vendas' {...props} />,
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      {
        label: 'Autorização de Pedido de Venda',
        route: '/modulo-vendas/autorizacao-pedido-venda',
      },
      { label: 'Pedidos de Venda', route: '/modulo-vendas/pedido-venda' },
      {
        label: 'Meta de Vendas',
        route: '/modulo-vendas/meta-vendas'
      }
    ],
  },
  {
    label: 'Preços',
    Icon: (props) => <img src={iconModuloPrecos} alt='ícone-preços' {...props} />,
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      { label: 'Cadeia de Preços', route: '/precos/cadeia-precos' },
      { label: 'Configuração de Preços', route: '/precos/configuracao-precos' }
    ]
  },
  {
    label: 'Financeiro',
    Icon: (props) => (
      <img src={iconFinanceiro} alt='ícone-financeiro' {...props} />
    ),
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      { label: 'Bandeiras de Cartões', route: '/modulo-financeiro/bandeira-cartao' },
      { label: 'Configuração de Remessa Bancária', route: '/modulo-financeiro/configuracao-remessa-bancaria' },
      { label: 'Conta Bancária', route: '/modulo-financeiro/conta-bancaria' },
      { label: 'Instituição Bancária', route: '/modulo-financeiro/instituicoes-bancarias' },
      { label: 'Operadora de Cartão', route: '/modulo-financeiro/operadoras-cartao' },
    ]
  },
  {
    label: 'Fiscal',
    Icon: (props) => <img src={iconFiscal} alt='ícone-fiscal' {...props} />,
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      {
        label: 'Cadastros',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          {
            label: 'Mensagem fiscal',
            route: '/modulo-fiscal/cadastro-mensagem-fiscal',
          },
          {
            label: 'Natureza da Operação',
            route: '/modulo-fiscal/natureza-da-operacao',
            showProd: true,
          },
          {
            label: 'Natureza de crédito Pis/Cofins',
            route: '/modulo-fiscal/natureza-credito-pis-cofins',
          },
          { label: 'Operação', route: '/modulo-fiscal/cadastro-de-operacao' },
        ],
      },
      {
        label: 'Figuras Fiscais',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          { label: 'ICMS', route: '/modulo-fiscal/figura-fiscal-icms' },
          { label: 'ISS', route: '/modulo-fiscal/figura-fiscal-iss' },
          {
            label: 'Pis/Cofins',
            route: '/modulo-fiscal/figura-fiscal-piscofins',
          },
        ],
      },
      {
        label: 'Importação',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          {
            label: 'Importar CTe',
            route:
              '/modulo-documento-fiscal/importar-conhecimento-transporte-eletronico',
          },
          {
            label: 'Importar NFe',
            route: '/modulo-documento-fiscal/importar-nota-fiscal',
          },
        ],
      },
      {
        label: 'Tabelas',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          {
            label: 'Benefício fiscal',
            route: '/modulo-fiscal/cod-beneficio-fiscal',
          },
          { label: 'CEST', route: '/modulo-fiscal/inserir-cest' },
          {
            label: 'CFOP',
            route: '/modulo-fiscal/codigo-fiscal-de-operacao-e-prestacao',
          },
          { label: 'CNAE', route: '/modulo-fiscal/cnae' },
          { label: 'CSOSN', route: '/modulo-fiscal/inserir-csosn' },
          {
            label: 'CST',
            route: '/modulo-fiscal/codigo-de-situacao-tributaria',
          },
          {
            label: 'Código de Serviço',
            route: '/modulo-fiscal/cadastro-codigo-servicos',
          },
          { label: 'Gênero do Item', route: '/modulo-fiscal/genero-item' },
          { label: 'NCM', route: '/modulo-fiscal/ncm' },
          {
            label: 'Origem da Mercadoria',
            route: '/modulo-fiscal/origem-mercadoria',
          },
          {
            label: 'Regime Tributário',
            route: '/modulo-fiscal/regime-tributario',
          },
          { label: 'Tabela IBPT', route: '/modulo-fiscal/tabela-ibpt-por-ncm' },
          { label: 'Tipo de Item', route: '/modulo-fiscal/tipo-de-item' },
          {
            label: 'Tributação do Participante',
            route: '/modulo-fiscal/tributacao-participante',
          },
        ],
      },
      {
        label: 'Configuração', route: '/modulo-fiscal/configuracao-fiscal'
      }
    ],
  },
  {
    label: 'Configurações',
    Icon: (props) => (
      <img src={iconConfigAccess} alt='ícone-configurações-acesso' {...props} />
    ),
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      {
        label: 'RP Flex',
        childrensBackgroundColor: LevelColors.SECOND_LEVEL,
        childrens: [
          { label: 'Credêncial', route: '/rpflex/credencial' },
          { label: 'Host', route: '/rpflex/host' },
          { label: 'Pendência de Estoque', route: '/rpflex/pendencia-estoque' }
        ]
      }
    ],
  },
];

const menuLateralItensDefinitions = process.env.REACT_APP_IN_DEV
  ? itensMenuLateralDev
  : filterMenuItems(itensMenuLateralDev);

function filterMenuItems(menu: MenuLateralItemModel[]): MenuLateralItemModel[]  {
  return menu.map((item) => {
    if (item.childrens) {
      let filterChildren: MenuLateralItemModel[] = item.childrens || [];

      if (!item.showProd) {
        filterChildren = filterMenuItems(filterChildren);
      }

      if (filterChildren.length) {
        return {
          ...item,
          childrens: filterChildren
        }
      } else return null
    } else if (item.showProd) return item;
    else return null;
  }).filter(item => item !== null) as  MenuLateralItemModel[];
}


/**
 * Array com a ordem do menu lateral.
 * para reordenar o menu lateral, basta alterar o possicionamento da label no array.
 */
const order = [
  'Dashboard', 'Cadastros', 'PCP', 'Estoque', 'Compras', 'Logistica',
  'Faturamento', 'Ordem de Serviço', 'Questionários', 'Vendas',
  'Preços', 'Financeiro', 'Fiscal', 'Configurações'
];

/**
 * Ordena os itens do menu lateral de acordo com a ordem especificada.
 * @param menuLateralItensDefinitions Array contendo as definições dos itens do menu lateral.
 * @param order Array que define a ordem desejada para os itens do menu lateral.
 */
menuLateralItensDefinitions.sort((a, b) => {
  const indexA = order.indexOf(a.label as string);
  const indexB = order.indexOf(b.label as string);

  if (indexA === -1 && indexB === -1) return 0;
  else if (indexA === -1) return 1;
  else if (indexB === -1) return -1;
  else return indexA - indexB;
});

export default menuLateralItensDefinitions;
