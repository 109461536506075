import React from 'react';
import { Checkbox, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';
import { useStyles } from './spreadsheet.styles';
import { getItemValue } from 'root-utils/item';

import { Group } from './spreadsheet.types';

interface SpreadSheetProps<T> {
  /**
   * Lista de grupos da planilha
   */
  header: Group[];
  /**
   * Dados da planilha
   */
  data: T[];
  /**
   * Item Selecionado
   */
  selected?: T;
  /**
   * Função para alterar item selecionado
   * @param selected Item selecionado
   * @returns {void}
   */
  setSelected?: (selected: T) => void;
}

/**
 * Componente de Planilha
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @param header Lista de grupos da planilha
 * @param data Dados da planilha
 * @param selected Item Selecionado
 * @param setSelected Função para alterar item selecionado
 */
function SpreeadSheet<T>({ header, data, selected, setSelected }: SpreadSheetProps<T>): JSX.Element {
  const classes = useStyles();

  /**
   * Renderiza o cabeçalho da planilha
   * @returns 
   */
  const renderHeader = () => (
    <thead>
      <tr>
        {header.map(group => (
          <div style={{ flex: group.flex || 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <td style={{ flex: group.flex || 1 }}>
              {group.title}
            </td>
            <div style={{ display: 'flex', flex: 1 }}>
              {group.cols.map(column => (
                <td style={{ flex: 1, borderRight: column.hideDivider ? 'none' : `2px solid #dae3ee` }}>
                  <span>{column.header}</span>
                </td>
              ))}
            </div>
          </div>
        ))}
      </tr>
    </thead>
  );

  /**
   * Renderiza as linhas da planilha
   * @returns 
   */
  const renderRows = () => (
    <tbody>
      {data.map((item, itemIndex) => (
        <tr className={itemIndex % 2 === 0 ? 'ag-row-even' : 'ag-row-odd'}>
          {header.map((group, groupHeaderIndex) =>
            <div style={{ flex: group.flex || 1 }}>
              {group.cols.map(column => {
                let show = '';

                if (column.field) {
                  show = !column.editable ? getItemValue(column.field, item) : column.editable(getItemValue(column.field, item), itemIndex);
                  show = column.formater ? column.formater(show) : show;
                } else if (column.value)
                  show = column.value(itemIndex);

                return (
                  <td style={{ flex: 1, borderRight: column.hideDivider ? 'none' : undefined, paddingRight: 10 }}>
                    {groupHeaderIndex === 0 && !group.hideCheck &&
                      <Checkbox
                        data-testid={`radio-check-${groupHeaderIndex}`}
                        icon={<div className={Boolean(JSON.stringify(selected) !== JSON.stringify(item)) ? classes.radio : classes.selectedIcon} />}
                        onChange={() => setSelected && setSelected(item)}
                        checked={Boolean(JSON.stringify(selected) === JSON.stringify(item))}
                        checkedIcon={
                          <div className={classes.selectedIcon}>
                            <Done className={classes.circle} />
                          </div>
                        }
                      />
                    }
                    {['string', 'number'].includes(typeof show)  ?
                      <Tooltip title={show}>
                        <span>{show}</span>
                      </Tooltip> : show
                    }
                    {column.icon && column.icon(itemIndex)}
                  </td>
                );
              })}
            </div>
          )}
        </tr>
      ))}
    </tbody>
  );

  /**
   * Renderiza o footer da tabela
   * @returns 
   */
  const renderFooter = () => (
    <tfoot style={{ height: '40px !important' }}>
      <tr>
        {header.map((group, groupIndex) =>
          <div style={{ flex: group.flex || 1, display: 'flex' }}>
            {group.cols.map(column => (
              <td style={{ flex: 1, borderRight: column.hideDividerFooter ? 'none' : undefined, overflow: 'hidden' }}>
                <Tooltip title={column.footerValue || ''}>
                  <span>{groupIndex === 0 ? 'Valor Total:' : column.footerValue}</span>
                </Tooltip>
              </td>
            ))}
          </div>
        )}
      </tr>
    </tfoot>
  );

  return (
    <table className={classes.table}>
      {renderHeader()}
      {renderRows()}
      {renderFooter()}
    </table>
  );
}

export default SpreeadSheet;