import React from 'react'
import { Box } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Data {
    data: number[]
    backgroundColor: string[]
}

export type GraficoBarrasPropType = {
    labels: string[]
    datasets: Data[]
    tamanhoGrafico: number
    eixo?: 'horizontal' | 'vertical'
}

function GraficoBarrasHorizontal(props: GraficoBarrasPropType): JSX.Element {
    const { labels, datasets, tamanhoGrafico, eixo = 'vertical' } = props

    return (
        <Box height={tamanhoGrafico} width={'100%'}>
            <Bar
                options={{
                    indexAxis: eixo === 'vertical' ? 'x' : 'y',
                    responsive: true,
                    plugins: {
                        legend: {
                            display: Boolean(eixo === 'vertical'),
                            position: 'left',
                            align: 'center',
                        },
                        tooltip: {
                            enabled: true,
                        },
                        title: {
                            display: false,
                        },
                    }
                }}
                style={{ maxHeight: tamanhoGrafico, paddingTop: 15, height: '100%' }}
                data={{ labels, datasets }}
            />
        </Box>
    )
}

export default GraficoBarrasHorizontal