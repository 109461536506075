import { Dispatch } from "redux";
import { MainStateType } from "root-states";
import { useDispatch, useSelector } from "react-redux";
import { DispatchAction } from "root-states/root-dispatcher";
import AppLayoutActions from "root-states/actions/app-layout-actions";
import { AppLayoutStateType } from "root-states/reducers/app-layout-reducer";

interface LayoutProps {
  /**
   * Actions para controlar o estado do layout
   */
  actions: AppLayoutActions;
  /**
   * Estado global do layout
   */
  reducer: AppLayoutStateType;
}

/**
 * Hook para ter acesso ao redux layout
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @returns actions e reducer do layout
 */
function useLayout(): LayoutProps {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const appLayoutReducer = useSelector<MainStateType, AppLayoutStateType>((state) => state.appLayoutReducer);

  return {
    actions: appLayoutActions,
    reducer: appLayoutReducer
  }
}

export default useLayout;