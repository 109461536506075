import moment from "moment";

const tz = moment().utcOffset() / 60;

/**
 * Formata uma data para o formato ISO 8601 (YYYY-MM-DDTHH:mm:ss.ZZ) com a hora zerada.
 * @param date data a ser enviada na requisição (string | Date)
 * @returns string
 */
export function packDate(date: string | Date): string {
    return moment(date)
        .set('h', 0)
        .utcOffset(tz)
        .add(tz, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss.ZZ');
}

/**
 * Formata uma data para o formato ISO 8601 (YYYY-MM-DDTHH:mm:ss.ZZ).
 * @param date data a ser enviada na requisição (string | Date)
 * @returns string
 */
export function packDateTime(date: string | Date): string {
    return moment(date)
        .utcOffset(tz)
        .add(tz, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss.ZZ');
}

/**
 * Formata uma data para o formato ISO 8601 (YYYY-MM-DDTHH:mm:ss.ZZ) ignorando a zona de tempo
 * @param date data a ser recebida da requisição (string | Date)
 * @returns Date
 */
export function unpackDate(date: string | Date): Date {
    return moment(date)
        .utcOffset(tz)
        .add(-tz, 'hour')
        .toDate();
}