import React from 'react';
import { useStyles } from './grid-card.styles';

interface GridCardTitleProps {
  text: string;
}

function GridCardTitle(props: GridCardTitleProps): JSX.Element {
  const { text } = props;
  const classes = useStyles();

  return (
    <h3 className={classes.title}>{text}</h3>
  )
}

export default GridCardTitle;