/**
 * Converter string para números
 * @param value string no padrão . para milhar e , para decimal
 * @returns {number} Valor numérico
 */
function stringToNumber(value?: string): number {
  if(!value) return 0;

  return parseFloat(value.replaceAll('.','').replace(',', '.'));
}

/**
 * Converte um número para uma string no padrão . para milhar e , para decimal
 * @param value Valor numérico
 * @param decimalScale Número de casas decimais
 * @returns {string}
 */
function numberToString(value: number, decimalScale?: number): string {
  return new Intl.NumberFormat('pt-br', { minimumFractionDigits: decimalScale || 0, maximumFractionDigits: decimalScale }).format(value);
}

/**
 * Converte um número para uma moeda (R$)
 * @param value Valor númerico
 * @param currency Moeda (padrão R$)
 * @returns {string}
 */
function convertToCurrency(value: number, currency?: string): string {
  return new Intl.NumberFormat('pt-br', { style: 'currency', currency: currency || 'BRL' }).format(value);
}

export { stringToNumber, numberToString, convertToCurrency };