import React, { useState } from 'react'
import { Line } from "react-chartjs-2";
import { Box, useTheme } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
} from 'chart.js'


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export type Data = {
    data: number[]
    backgroundColor: string
    label?: string
    borderWidth?: number
    borderColor?: string
    fill?: boolean
    pointRadius?: number
    borderDash?: number[]
    borderCapStyle?: "butt" | "round" | "square"
}

export type GraficoLinhaPropType = {
    labels: string[]
    datasets: Data[]
    tamanhoGrafico: number
}


function GraficoLinhaDashboard(props: GraficoLinhaPropType): JSX.Element {
    const { datasets, labels, tamanhoGrafico } = props
    const [actualData, setActualData] = useState<number>(0)
    const [compData, setCompData] = useState<number>(0)
    const theme = useTheme()

    return (
        <Box height={tamanhoGrafico} width={'100%'} >
            <Line
                options={{
                    responsive: true,
                    normalized: true,
                    plugins: {
                        legend: {
                            display: true,
                            align: 'center',
                            position: 'bottom',

                            labels: {
                                boxHeight: 1,

                            }
                        },
                        tooltip: {
                            enabled: true,
                            displayColors: false,
                            callbacks: {
                                title: function (this, tooltipItem) {
                                    setActualData(datasets[0].data[tooltipItem[0].dataIndex])
                                    setCompData(datasets[1].data[tooltipItem[0].dataIndex])
                                    return tooltipItem[0].label;
                                },
                                afterTitle: function (context) {
                                    return 'Agendadas VS Finalizadas'
                                },
                                label: function (context) {
                                    return ''
                                },
                                afterBody: function (this, tooltipItem) {
                                    if (actualData < compData) {
                                        return `${actualData}             ↑ ${compData}`
                                    } else if (actualData > compData) {
                                        return `${actualData}             ↓ ${compData}`
                                    } else {
                                        return `${actualData}               ${compData} `
                                    }
                                },
                            },
                            bodyAlign: 'center',
                            titleAlign: 'center',
                            backgroundColor: '#FFFFFF',
                            titleColor: '#000000',
                            bodyColor: '#000000',
                            borderColor: `${theme.palette.primary.main}`,
                            borderWidth: 1
                        },
                        title: {
                            display: false
                        }
                    }
                }}
                style={{ maxHeight: tamanhoGrafico }}
                data={{ labels, datasets }}
            />
        </Box>
    )
}

export default GraficoLinhaDashboard
