/**
 * Enum de situação de Registro
 *
 * @author Carlos Bageston
 */
enum SituacaoRegistroEnum {
  CREATE,
  UPDATE,
  DELETE,
  NOT_CHANGED
}

export default SituacaoRegistroEnum;
