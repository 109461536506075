import React, { ReactNode } from 'react';
import { Modal, Box } from '@mui/material';
import { useStyles } from './modal.styles';

interface CustomModalProps {
  open: boolean;
  children: ReactNode;
  handleClose: () => void;
}

/**
 * Componente para Modal customizado
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @param children Conteúdo do Modal
 * @param open Estado de visualização do Modal
 * @param handleClose Função que será executada ao fechar o modal 
 * @returns 
 */
function CustomModal({ children, open, handleClose }: CustomModalProps): JSX.Element {
  const classes = useStyles();
  
  return (
    <Modal open={open} onClose={handleClose} classes={{ root: classes.modal }}>
      <Box className={classes.customContent}>
        {children}
      </Box>
    </Modal>
  )
}

export default CustomModal;