import React from 'react';
import { useStyles } from './modal.styles';
import { Modal as MUIModal, Box, Button } from '@mui/material';

import InfoIcon from '../../assets/icons/triangle-exclamation.svg';

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  onFinish?: (e: any) => void;
  title: string;
  message?: string | string[];
  onCloseLabel?: string;
  onFinishLabel?: string;
  optionsDescription?: string;
  loading?: boolean;
  showIcon?: boolean;
  customWidth?: number;
  onThirdButtonClick?: () => void;
  thirdButtonLabel?: string;
  thridButton?: boolean
}

/**
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * 
 * @param open visibilidade do modal
 * @param onClose função para botão de cancelar
 * @param onFinish função de submit
 * @param title titulo do modal
 * @param message mensagem a ser exibida
 * @param onCloseLabel label do botão de cancelar
 * @param onFinishLabel label do botão de confirmar
 * @param optionsDescription texto para descrever as oções do modal 
 * @returns 
 */
function Modal(props: ModalProps): JSX.Element {
  const classes = useStyles();
  const {
    open,
    onClose,
    onFinish,
    title,
    message,
    onCloseLabel,
    onFinishLabel,
    loading,
    optionsDescription,
    showIcon = true,
    customWidth,
    onThirdButtonClick,
    thirdButtonLabel,
    thridButton
  } = props;

  const renderMessage = () => {
    if (typeof (message) === 'string') return <p style={{ marginTop: 20 }}>{message}</p>;
    else return (
      <div style={{ marginTop: 20 }}>
        {message?.map((message, index) =>
          <p key={index}>{message}</p>
        )}
      </div>
    );
  };

  return (
    <div >
      <MUIModal open={open} onClose={onClose} classes={{ root: classes.modal }}>
        <Box className={classes.content} style={{ width: customWidth }}>
          {!loading && showIcon &&
            <section id='icon' className={classes.infoIcon}>
              <img src={InfoIcon} alt='ícone-modal' />
            </section>
          }
          <section id='info' style={loading ? { display: 'flex', alignItems: 'center', flexDirection: 'column' } : { marginLeft: 10, flex: 5 }}>
            {loading &&
              <svg className={classes.loading} viewBox="0 0 50 50">
                <circle className={'path'} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
              </svg>
            }
            <h2 className={classes.title}>
              {title}
            </h2>
            {message && renderMessage()}
            {!loading && (
              <>
                {optionsDescription &&
                  <div className={classes.optionsDescription}>
                    <h2 className={classes.title}>{optionsDescription}</h2>
                  </div>
                }
                <div className={classes.options} style={{ marginTop: message ? 20 : undefined, justifyContent: thridButton ? 'space-between' : undefined, marginLeft: thridButton ? '-50px' : undefined }}>
                  {onClose &&
                    <Button
                      className={classes.option}
                      style={{ backgroundColor: '#7B7B7B' }}
                      onClick={onClose}
                    >
                      {onCloseLabel ?? 'Cancelar'}
                    </Button>
                  }
                  {onThirdButtonClick &&
                    <Button
                      className={classes.option}
                      style={{ backgroundColor: '#223771' }}
                      onClick={onThirdButtonClick}
                    >
                      {thirdButtonLabel ?? ''}
                    </Button>
                  }
                  {onFinish &&
                    <Button
                      className={classes.option}
                      style={{ backgroundColor: '#223771' }}
                      onClick={onFinish}
                    >
                      {onFinishLabel ?? 'Excluir'}
                    </Button>
                  }
                </div>
              </>
            )
            }
          </section>
        </Box>
      </MUIModal>
    </div>
  );
}

export default Modal;