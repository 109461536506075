import GridCardRoot from "./grid-card";
import GridCardIcon from "./grid-card-icon";
import GridCardPercecntage from "./grid-card-percentage";
import GridCardContent from "./grid-card-text";
import GridCardTitle from "./grid-card-title";

export const GridCard = {
  Root: GridCardRoot,
  Title: GridCardTitle,
  Content: GridCardContent,
  Icon: GridCardIcon,
  Percentage: GridCardPercecntage
}