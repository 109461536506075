import React from 'react'
import { Box } from '@mui/material'
import { Pie } from 'react-chartjs-2'
import { FiberManualRecord } from '@mui/icons-material'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

import { useStyles } from './grafico.styles'

ChartJS.register(ArcElement, Tooltip, Legend);

interface Data {
  label?: string
  data: number[]
  backgroundColor: string[]
  borderColor?: string[],
  borderWidth?: number
}

export type GraficoPizzaPropType = {
  labels: string[],
  datasets?: Data,
  tamanhoGrafico?: number
}

function GraficoPizza(props: GraficoPizzaPropType): JSX.Element {
  const { labels, datasets, tamanhoGrafico } = props
  const classes = useStyles()

  return (
    <Box height={tamanhoGrafico} display='flex' justifyContent={'space-around'}>
      <div>
        <Pie 
          options={{
            plugins: {
              legend: {
                display: false,
                position: 'right',
                align: 'center'
              },
            },
            borderColor: 'transparent'
          }}
          style={{ maxHeight: tamanhoGrafico, maxWidth: tamanhoGrafico }}
          data={{ datasets: datasets ? [datasets] : [], labels }} 
        />
      </div>
      <div className={classes.legendasContainer} style={{ maxHeight: tamanhoGrafico, maxWidth: tamanhoGrafico }}>
        {labels.map((label, index) => (
          <Box display={'flex'} alignItems='center' marginBottom={'20px'} key={index}>
            <FiberManualRecord style={{ color: datasets?.backgroundColor[index] }}/>
            <div className={classes.legenda}>
              {label}: {datasets?.data[index]}
            </div>
          </Box>
        ))}
      </div>
    </Box>
  )
};

export default GraficoPizza