import React from 'react';
import { useStyles } from './grid-card.styles';
import { numberToString } from 'root-utils/numbers';

interface GridCardPercentageProps {
  value: number;
}

function GridCardPercecntage(props: GridCardPercentageProps): JSX.Element {
  const { value } = props;
  const classes = useStyles();

  return (
    <div className={classes.percentageContainer}>
      <h4>{numberToString(value)}%</h4>
    </div>
  )
}

export default GridCardPercecntage