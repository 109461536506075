import React from 'react';
import { useStyles } from './grid-card.styles';

interface GridCardTextProps {
  text: string;
}

function GridCardContent(props: GridCardTextProps): JSX.Element {
  const { text } = props;
  const classes = useStyles();

  return <p className={classes.text}>{text}</p>
}

export default GridCardContent;