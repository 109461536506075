import { makeStyles } from "@mui/styles";

/**
 * Estilos para componente de planilha
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
const useStyles = makeStyles((theme: any) => ({
  table: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& tr': {
      flex: 1,
      display: 'flex',
      width: '100%',
    },
    '& td': {
      padding: '0 10px',
      borderRight: '2px solid #dae3ee',
      height: 40,
      maxHeight: 40,
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& thead, tfoot': {
      width: '100%',
      flex: 1,
      height: 80,
      background: '#f0f4f8',
      borderTop: '1px solid #dae3ee',
      borderBottom: '1px solid #dae3ee',
      display: 'flex',
      alignItems: 'center',
      '& td': {
        borderBottom: '2px solid #dae3ee',
        fontWeight: 700,
        fontFamily: 'Open Sans',
        color: '#95a2b9',
      },
      '& span': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }
    },
    '& tbody': {
      display: 'flex',
      flexDirection: 'column',
      '& div': {
        display: 'flex',
        textOverflow: 'ellipsis', 
        overflow: 'hidden', 
        whiteSpace: 'nowrap'
      },
      '& td': {
        [theme.breakpoints.down('lg')]: {
          display: 'block',
          padding: '10px 10px 0 10px',
        }
      },
    },
  },
  radio: {
    height: 18,
    width: 18,
    border: `1px solid #95A2B9`,
    borderRadius: 10,
    display: 'flex'
  },
  selectedIcon: {
    height: 18,
    width: 18,
    borderRadius: 10,
    background: ` #223771`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  circle: {
    height: '10px !important',
    width: '25px !important',
    color: '#FFF !important'
  },
}));

export { useStyles };