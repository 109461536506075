import { makeStyles } from '@mui/styles';

/**
 * Estilos do componente HorizontalTabs
 */
const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  horizontalTabs: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `2px solid ${theme.palette.grey[100]}`,
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  tabButton: {
    '&:hover': {
      backgroundColor: `${theme.palette.action.selected}`,
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,
    },
    backgroundColor: 'transparent',
    height: '100%',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 15,
    paddingLeft: 15,
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    cursor: 'pointer',
    borderBottom: 'none',
  },
  tabButtonDisabled: {
    '&:hover': {
      backgroundColor: 'transparent !important',
      opacity: 1,
    },
    color: `${theme.palette.text.disabled} !important`,
    cursor: 'default !important',
  },
  tabButtonSelected: {
    backgroundColor: `transparent !important`,
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 500,
  },
  tabLabel: {
    flex: 1,
    padding: '10px 20px'
  },
  tabContent: {
    flex: 1, 
    overflowY: 'auto',
  },
  titleContainer: {
    maxHeight: 44,
    padding: '12px 24px',
    fontSize: 18,
    color: '#000000',
    fontWeight: 400,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
}));

export { useStyles };
