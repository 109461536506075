import React, { useCallback, useMemo, useState } from 'react';
import Modal from '../modal/modal';
import { useTheme } from '@mui/material';
import { useStyles } from './table.styles';
import { useKeyboardControls } from 'root-utils/hooks/use-keyboard-controls';
import DataTable, { DataTablePropType } from '@bubotech/sumora-react-components/lib/datatable';
import ButtonGrid, { ButtonGridPropType } from '@bubotech/sumora-react-components/lib/buttongrid';
import GroupButtonGrid, { GroupButtonGridPropType } from '@bubotech/sumora-react-components/lib/groupbuttongrid';

export interface TableState<T> {
  selected?: T;
  showDelete: boolean;
  selectedIndex: number;
  key: number;
}

export interface CustumButtonTable<T> extends Omit<ButtonGridPropType, 'onClick'>{
  enableWithSelected?: boolean;
  showWithSelected?: boolean;
  onClick: (selected: T | undefined, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface TablePropType<T> extends DataTablePropType<T>, 
  Omit<GroupButtonGridPropType, 'onClickEdit' | 'onClickDelete' | 'onClickAdd' | 'customButtons'> {
    onClickEdit?: (selected: T) => void;
    onClickAdd?: () => void;
    onClickDelete?: (selected: T) => void;
    enableAddWithSelected?: boolean;
    enableEditWithSelected?: boolean;
    enableDeleteWithSelected?: boolean;
    className?: string;
    customButtons?: CustumButtonTable<T>[];
    fullScreen?: boolean;
    enableControls?: boolean;
    nextTab?: boolean;
    previousTab?: boolean;
    addShortcut?: () => void;
  } 

/**
 * Componente de Tabela integrado com button grid
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 */
function Table<T = any>(props: TablePropType<T>): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const [tableState, setTableState] = useState<TableState<T>>({
    selected: undefined,
    showDelete: false,
    selectedIndex: -1,
    key: 0
  });

  const { 
    showAdd = false,
    showEdit = true,
    showDelete = true,
    buttonAddProps,
    buttonDeleteProps,
    buttonEditProps,
    columns,
    data,
    fullScreen = false,
    customButtons = [],
    showPagination = false,
    enableControls = false,
    nextTab = false,
    previousTab = false,
    enableAddWithSelected = false,
    enableDeleteWithSelected = true,
    enableEditWithSelected = true,
    className = '',
    onClickAdd,
    onClickEdit,
    onClickDelete,
  } = props;

  const tableCustomButtons = useMemo(() => customButtons.map(button => 
    <ButtonGrid
      {...button}
      disabled={button.enableWithSelected ? !Boolean(tableState.selected) : button.disabled}
      onClick={(e) => button.onClick(tableState.selected, e)}
      show={button.showWithSelected ? !Boolean(tableState.selected) : button.show}
      disabledColor={theme.palette.grey['100']}
    />
  ), [customButtons, tableState.selected, theme.palette])

  function handleClickEdit() {
    if (!tableState.selected || tableState.selectedIndex === -1 || !onClickEdit) return;

    onClickEdit(tableState.selected);
    setTableState(prev => ({...prev, selected: undefined}));
  }

  function handleClickDelete() {
    if (!tableState.selected || !onClickDelete) return;

    onClickDelete(tableState.selected);
    setTableState(prev => ({...prev, showDelete: false}));
  }

  const handleTab = useCallback(() => {
    let { selectedIndex, selected } = tableState;
    const newIndex = selectedIndex + 1;

    selectedIndex = newIndex <= data.length ? newIndex : 0;
    selected = newIndex <= data.length ? data[newIndex] : data[0];

    setTableState(prev => ({...prev, selected, selectedIndex, key: Math.random()}));
  }, [tableState, data]);

  useKeyboardControls(
    [
      {
        keys: ['Tab'],
        onPress: handleTab
      }
    ],
    enableControls,
    nextTab,
    previousTab,
    onClickAdd
  )

  return (
    <>
      <GroupButtonGrid
        showAdd={showAdd}
        showEdit={showEdit}
        showDelete={showDelete}
        {...props}
        onClickAdd={onClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
        customButtons={tableCustomButtons}
        buttonAddProps={{
          ...buttonAddProps,
          disabled: enableAddWithSelected ? !tableState.selected : buttonAddProps?.disabled,
          disabledColor: theme.palette.grey['100'],
          backgroundColor: theme.palette.primary.main
        }}
        buttonDeleteProps={{
          ...buttonDeleteProps,
          disabled: enableDeleteWithSelected ? !tableState.selected : buttonDeleteProps?.disabled,
          disabledColor: theme.palette.grey['100'],
          onClick: () => setTableState(prev => ({...prev, showDelete: true}))
        }}
        buttonEditProps={{
          ...buttonEditProps,
          disabled: enableEditWithSelected ? !tableState.selected : buttonEditProps?.disabled,
          disabledColor: theme.palette.grey['100'],
          backgroundColor: theme.palette.primary.main
        }}
      />
      <div className={`${classes.containerDataTable} tabela ${className}`} style={fullScreen ? { height: '92%' } : undefined}>
        <DataTable<T>
          key={tableState.key}
          initialSelected={tableState.selected}
          {...props}
          onSelectRow={props.rowSelectionType !== 'multiple' ? selected => {
            setTableState(prev => ({
              ...prev, 
              selected: selected,
              selectedIndex: data.findIndex(item => JSON.stringify(item) === JSON.stringify(selected))
            }))

            props.onSelectRow && props.onSelectRow(selected);
          } : undefined}
          data={data}
          columns={columns}
          showPagination={showPagination}
        />
      </div>

      <Modal 
        open={tableState.showDelete}
        title='Clicando em EXCLUIR você estará removendo esse item da lista.'
        message='Tem certeza que deseja realizar esta ação?'
        onFinishLabel='EXCLUIR'
        onCloseLabel='CANCELAR'
        onClose={() => setTableState(prev => ({...prev, showDelete: false}))}
        onFinish={handleClickDelete}
      />
    </>
  )
}

export default Table;