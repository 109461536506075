import React  from 'react';
import { useStyles } from './style';
import clsx from 'clsx';


/**
 * Modelo de tab para o componente HorizontalTabs
 *
 * @author Carlos Bageston <carlos.bageston@kepha.com.br>
 * @interface HorizontalTabsTab
 */
export type HorizontalTabsTab = {
  label: string | JSX.Element;
  content: any;
  disabled?: boolean;
  tabTitle?: string;
  tabOptions?: React.ReactNode[];
};

export type HorizontalTabsProps = {
  tabs: HorizontalTabsTab[];
  onChangeIndex?: (index: number) => void;
  selectedIndex?: number;
  title?: string;
};

/**
 * Componente de Tabs na Horizontal customizado
 *
 * @author Carlos Bageston <carlos.bageston@kepha.com.br>
 * @param {HorizontalTabsProps} props
 */

function HorizontalTabs(props: HorizontalTabsProps): JSX.Element {
  const { onChangeIndex, tabs, selectedIndex = 0, title } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          {title && (
            <div className={classes.titleContainer}>
              {tabs[selectedIndex].tabTitle ?? title}
              <div style={{ display: 'flex' }}>
                {tabs[selectedIndex].tabOptions?.map(option => option)}
              </div>
            </div>
          )}
        </div>
      <div className={classes.horizontalTabs}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={clsx(
              classes.tabButton,
              index === selectedIndex && classes.tabButtonSelected,
              tab.disabled && classes.tabButtonDisabled
            )}
            onClick={() => {
              if (!tab.disabled && onChangeIndex) {
                onChangeIndex(index);
              }
            }}
          >
            <div className={classes.tabLabel}>{tab.label}</div>
          </div>
        ))}
      </div>
      <div className={classes.tabContent}>{tabs[selectedIndex].content}</div>
    </div>
  );
}

export default HorizontalTabs;