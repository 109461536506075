import { makeStyles } from '@mui/styles';

/**
 * Estilos do modal de usuario cadastrado
 **/
const useStyles = makeStyles((theme: any) => ({
  content: {
    borderRadius: 10,
    padding: '38px 17px 20px 17px',
    backgroundColor: '#FFF',
    position: 'absolute',
    top: '35%',
    left: '40%',
    maxWidth: '466px',
    maxHeight: '268px',
    overflow: 'hidden',
    display: 'flex'
  },
  message: {
    fontFamily: 'Open Sans',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '18px',
    color: `${theme.palette.grey['700']}`,
    width: 403,
  },
  lista: {
    height: 60,
    overflowY: 'auto',
    paddingBottom: 5,
    borderBottom: '1px solid #707070',
    marginBottom: 7,
    '& p': {
      fontSize: '13px',
      fontFamily: 'Open Sans',
      fontWeight: 300,
      color: `${theme.palette.grey['700']}`
    }
  },
  info: {
    fontSize: '11px',
    fontFamily: 'Open Sans',
    fontWeight: 300,
    color: `${theme.palette.grey['700']}`
  },
  options: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '27px'
  },
  option: {
    borderRadius: '16px !important',
    color: '#FFF !important',
    padding: '5px 15px !important',
    margin: '0px 3px !important',
    fontFamily: 'Open Sans !important'
  }
}));

export { useStyles };
