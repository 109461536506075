import { makeStyles } from '@mui/styles';

/**
 * Estilos do gráfico de linhas
 **/
const useStyles = makeStyles((theme: any) => ({
  comparacao: {
    position: 'absolute',
    top: 50,
    padding: '5px 7px',
    backgroundColor: `${theme.palette.secondary.main}`,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 2,
  },
  comparacaoTitulo: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: `${theme.palette.grey[500]}`,
    margin: 0
  },
  legenda: {
    fontFamily: 'Roboto',
    fontSize: 12,
    lineHeight: '20px',
    margin: 0,
    color: `${theme.palette.text.secondary}`
  }
}));

export { useStyles };
