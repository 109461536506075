import { lazy } from 'react';

import Dashboard from 'root-views/dashboard/dashboard';

const ClientList = lazy(() => import('./cadastros/pessoas/clientes/clientes'));
const EditClient = lazy(() => import('./cadastros/pessoas/clientes/editar-clientes'));
const CollaboratorList = lazy(() => import('root-views/cadastros/pessoas/colaborador/colaborador'));
const EditCollaborator = lazy(() => import('root-views/cadastros/pessoas/colaborador/editar-colaborador'));
const SupplierList = lazy(() => import('./cadastros/pessoas/fornecedor/fornecedor'));
const EditSupplier = lazy(() => import('./cadastros/pessoas/fornecedor/editar-fornecedor'));
const RepresentativeList = lazy(() => import('./cadastros/pessoas/representante/representante'));
const EditRepresentative = lazy(() => import('./cadastros/pessoas/representante/editar-representante'));
const SellerList = lazy(() => import('./cadastros/pessoas/vendedor/vendedor'));
const EditSeller = lazy(() => import('./cadastros/pessoas/vendedor/editar-vendedor'));
const TechnicianList = lazy(() => import('./cadastros/pessoas/tecnico/tecnico'));
const EditTechnician = lazy(() => import('./cadastros/pessoas/tecnico/editar-tecnico'));
const ProductList = lazy(() => import('./cadastros/produtos/produto/produto'));
const EditProduct = lazy(() => import('./cadastros/produtos/produto/editar-produto'));
const ProdutDatasheetList = lazy(() => import('./cadastros/produtos/fichatecnica/views/ficha-tecnica'));
const EditProductDatasheet = lazy(() => import('./cadastros/produtos/fichatecnica/views/editar-ficha-tecnica'));
const NutritionalCategoryList = lazy(() => import('./cadastros/categorianutricional/views/categoria-nutricional'));
const EditNutritionalCategory = lazy(() => import('./cadastros/categorianutricional/views/editar-categoria-nutricional'));
const NutritionalReferenceList = lazy(() => import('./cadastros/referencianutricional/views/referencia-nutricional'));
const EditNutritionalReference = lazy(() => import('./cadastros/referencianutricional/views/editar-referencia-nutricional'));
const ProductColorList = lazy(() => import('./cadastros/produtos/corproduto/cor-produto'));
const EditProductColor = lazy(() => import('./cadastros/produtos/corproduto/editar-cor-produto'));
const ProductBrandList = lazy(() => import('./cadastros/produtos/marca/marca'));
const EditProductBrand = lazy(() => import('./cadastros/produtos/marca/editar-marca'));
const ProductStageList = lazy(() => import('./cadastros/produtos/etapa/etapa'));
const EditProductStage = lazy(() => import('./cadastros/produtos/etapa/editar-etapa'));
const ProductMeasurementUnitList = lazy(() => import('./cadastros/produtos/unidademedida/unidade-medida'));
const EditProductMeasurementUnit = lazy(() => import('./cadastros/produtos/unidademedida/editar-und-medida'));
const ProductGroupList = lazy(() => import('./cadastros/produtos/estruturamercadologica/grupo/grupo'));
const EditProductGroup = lazy(() => import('./cadastros/produtos/estruturamercadologica/grupo/editar-grupo'));
const ProductSubgroupList = lazy(() => import('./cadastros/produtos/estruturamercadologica/subgrupo/subgrupo'));
const EditProductSubgroup = lazy(() => import('./cadastros/produtos/estruturamercadologica/subgrupo/editar-subgrupo'));
const ProductSectionList = lazy(() => import('./cadastros/produtos/estruturamercadologica/secao/secao'));
const EditProductSection = lazy(() => import('./cadastros/produtos/estruturamercadologica/secao/editar-secao'));
const ProductDivisionList = lazy(() => import('./cadastros/produtos/estruturamercadologica/divisao/divisao'));
const EditProductDivision = lazy(() => import('./cadastros/produtos/estruturamercadologica/divisao/editar-divisao'));
const CompanyList = lazy(() => import('./cadastros/empresa/empresas'));
const EditCompany = lazy(() => import('./cadastros/empresa/editar-empresa'));
const BranchList = lazy(() => import('./cadastros/filial/filial'));
const EditBranch = lazy(() => import('./cadastros/filial/editar-filial'));
const StructureSectorList = lazy(() => import('./cadastros/setor/views/setor'));
const EditStructureSector = lazy(() => import('./cadastros/setor/views/editar-setor'));
const Department = lazy(() => import('./cadastros/departamento/views/departamento'));
const DepartmentEdit = lazy(() => import('./cadastros/departamento/views/edit-departamento'));
const OrganizationalSructureList = lazy(() => import('root-views/cadastros/estruturaorganizacional/estruturaorganizacional/views/estrutura-organizacional'));
const EditOrganizationalSructure = lazy(() => import('root-views/cadastros/estruturaorganizacional/estruturaorganizacional/views/editar-estrutura-organizacional'));


const TaxRegimeList = lazy(() => import('./fiscal/regimetributario/regime-tributário'));
const EditTaxRegime = lazy(() => import('./fiscal/regimetributario/editar-regime-tributario'));
const CNAESList = lazy(() => import('./fiscal/cnae/cnae'));
const EditCNAE = lazy(() => import('./fiscal/cnae/editar-cnae'));
const NCMList = lazy(() => import('root-mf/ncm/views/ncm'));
const EditNCM = lazy(() => import('root-mf/ncm/views/editar-ncm'));
const InsertCEST = lazy(() => import('root-mf/cest/views/inserir-cest'));
const ViewInsertCEST = lazy(() => import('root-mf/cest/views/view-inserir-cest'));
const CFOPList = lazy(() => import('root-mf/cfop/views/cfop'));
const EditCFOP = lazy(() => import('root-mf/cfop/views/editar-cfop'));
const TaxBenefitCodeList = lazy(() => import('root-mf/codigobeneficiofiscal/views/cod-bene-fiscal'));
const ViewTaxBenefitCode = lazy(() => import('root-mf/codigobeneficiofiscal/views/dados-cod-bene-fiscal'));
const TaxConfiguration = lazy(() => import('root-mf/configuracao/views/configuracao-fiscal'));
const CSTList = lazy(() => import('root-mf/cst/views/cst'));
const EditCST = lazy(() => import('root-mf/cst/views/editar-cst'));
const ICMSTaxFigureList = lazy(() => import('root-mf/figurasfiscaisicms/views/tabela-figura-fiscal-icms'));
const EditICMSTaxFigure = lazy(() => import('root-mf/figurasfiscaisicms/views/crud-figura-fiscal-icms'));
const PISCOFINSTaxFigureList = lazy(() => import('root-mf/figurasfiscaispiscofins/views/figura-fiscal-pis-cofins'));
const EditPISCOFINSTaxFigure = lazy(() => import('root-mf/figurasfiscaispiscofins/views/figura-fiscal'));
const ItemGenreList = lazy(() => import('root-mf/generoitem/views/genero-item'));
const ViewItemGenre = lazy(() => import('root-mf/generoitem/views/visualizar-genero-item'));
const IBPTNCMList = lazy(() => import('root-mf/ibptporncm/views/importar-ibpt-por-ncm'));
const ViewIBPTNCM = lazy(() => import('root-mf/ibptporncm/views/vizualizar-ibpt'));
const TransportList = lazy(() => import('root-mf/importarconhecimentotransporte/views/importar-conhecimento-transporte'));
const EditTransport = lazy(() => import('root-mf/importarconhecimentotransporte/views/editar-conhecimento-transporte'));
const InvoiceList = lazy(() => import('root-mf/importarnotafiscal/views/importar-nota'));
const ImportInvoice = lazy(() => import('root-mf/importarnotafiscal/views/editar-importacao-nota'));
const ServiceCodeList = lazy(() => import('root-mf/inserircodigoservicos/views/codigo-servicos'));
const ViewServiceCode = lazy(() => import('root-mf/inserircodigoservicos/views/vizualizar-codigo-servico'));
const CSOSNList = lazy(() => import('root-mf/inserircsosn/views/csosn'));
const ViewCSOSN = lazy(() => import('root-mf/inserircsosn/views/vizualizar-csosn'));
const TaxFiguresISSList = lazy(() => import('root-mf/inserirfigurafiscaliss/views/figuras-fiscais-iss'));
const EditTaxFiguresISS = lazy(() => import('root-mf/inserirfigurafiscaliss/views/editar-figura-fiscal-iss'));
const FiscalMessageList = lazy(() => import('root-mf/inserirmensagemfiscal/views/mensagem-fiscal'));
const EditFiscalMessage = lazy(() => import('root-mf/inserirmensagemfiscal/views/cad-mensagem-fiscal'));
const NatureCreditPISCOFINSList = lazy(() => import('root-mf/naturezacreditopiscofins/views/natureza-credito-pis-cofins'));
const EditNatureCreditPISCOFINS = lazy(() => import('root-mf/naturezacreditopiscofins/views/editar-natureza-credito-pis-cofins'));
const NatureOperationList = lazy(() => import('root-mf/naturezaoperacao/views/natureza-operacao'));
const EditNatureOperation = lazy(() => import('root-mf/naturezaoperacao/views/editar-natureza-operacao'));
const OperationList = lazy(() => import('root-mf/operacao/views/operacao'));
const EditOperation = lazy(() => import('root-mf/operacao/views/editar-operacao'));
const MerchandiseOriginList = lazy(() => import('root-mf/origemmercadoria/views/origens-mercadoria'));
const ViewMerchandiseOrigin = lazy(() => import('root-mf/origemmercadoria/views/visualizar-origem-mercadoria'));
const ItemTypeList = lazy(() => import('root-mf/tipoItem/views/tipo-item'));
const EditItemType = lazy(() => import('root-mf/tipoItem/views/editar-tipo-item'));
const ParticipantTaxationList = lazy(() => import('root-mf/tributacaoparticipante/views/tributacao-participante'));
const EditParticipantTaxation = lazy(() => import('root-mf/tributacaoparticipante/views/edit-tributacao-participante'));

const SectorList = lazy(() => import('root-pcp/setor/views/setor'));
const EditSector = lazy(() => import('root-pcp/setor/views/editar-setor'));
const AllergensList = lazy(() => import('root-pcp/alergenos/view/alergenos'));
const AppointmentsList = lazy(() => import('root-pcp/apontamentos/views/apontamentos'));
const EditAppointments = lazy(() => import('root-pcp/apontamentos/views/editar-apontamentos'));
const PriorityList = lazy(() => import('root-pcp/cadastrodeprioridade/views/cadastro-prioridade'));
const EditPriority = lazy(() => import('root-pcp/cadastrodeprioridade/views/editar-cadastro-prioridade'));
const Calendar = lazy(() => import('root-pcp/calendario/views/calendario'));
const CancelAppontmentList = lazy(() => import('root-pcp/cancelamentoapontamentoordemproducao/views/cancelamento-apontamento'));
const EditCancelAppontment = lazy(() => import('root-pcp/cancelamentoapontamentoordemproducao/views/editar-cancelamento-apontamento'));
const CancelProductionOrderList = lazy(() => import('root-pcp/cancelamentoordemproducao/views/cancelamento-ordem-producao'));
const EditCancelProductionOrder = lazy(() => import('root-pcp/cancelamentoordemproducao/views/editar-cancelamento-ordem-producao'));
const QualityCharacteristicList = lazy(() => import('root-pcp/caracteristicadequalidade/views/caracteristica-qualidade'));
const EditQualityCharacteristic = lazy(() => import('root-pcp/caracteristicadequalidade/views/editar-caracteristica-qualidade'));
const NonComplianceCategoryList = lazy(() => import('root-pcp/caracteristicasnaoconformidade/views/categoria-nao-conformidade'));
const EditNonComplianceCategory = lazy(() => import('root-pcp/caracteristicasnaoconformidade/views/editar-categoria-nao-conformidade'));
const ProductionOrderDashboard = lazy(() => import('root-pcp/dashboardordemproducao/views/views-dashboard'));
const SpecificationList = lazy(() => import('root-pcp/especificacao/views/especificacao'));
const EditSpecification = lazy(() => import('root-pcp/especificacao/views/editar-especificacao'));
const ProductionManagementList = lazy(() => import('root-pcp/gestaoproducao/views/gestao-producao'));
const EditProductionManagement = lazy(() => import('root-pcp/gestaoproducao/views/editar-gestao-producao'));
const QualityInspectionList = lazy(() => import('root-pcp/inspecaoqualidade/views/tabela-inspecao-qualidade'));
const EditQualityInspection = lazy(() => import('root-pcp/inspecaoqualidade/views/editar-inspecao-qualidade'));
const WorkingDayList = lazy(() => import('root-pcp/jornadadetrabalho/views/jornada-trabalho'));
const EditWorkingDay = lazy(() => import('root-pcp/jornadadetrabalho/views/editar-jornada'));
const QualityReportList = lazy(() => import('root-pcp/laudosqualidade/views/laudos-qualidade'));
const EditQualityReport = lazy(() => import('root-pcp/laudosqualidade/views/editar-laudo-qualidade'));
const EconomicLotList = lazy(() => import('root-pcp/loteeconomicoproducao/views/lote-economico'));
const EditEconomicLot = lazy(() => import('root-pcp/loteeconomicoproducao/views/editar-loteeconomico'));
const ProductionBatchList = lazy(() => import('root-pcp/loteordemproducao/view/lote-producao'));
const EditProductionBatch = lazy(() => import('root-pcp/loteordemproducao/view/editar-lote-producao'));
const MachineryList = lazy(() => import('root-pcp/maquinario/views/maquinario'));
const EditMachinery = lazy(() => import('root-pcp/maquinario/views/editar-maquinario'));
const NonComplianceList = lazy(() => import('root-pcp/naoconformidadesv2/view/nao-conformidade'));
const NonComplianceAppointment = lazy(() => import('root-pcp/naoconformidadesv2/view/nc-apontamentos'));
const EditNonCompliance = lazy(() => import('root-pcp/naoconformidadesv2/view/editar-nao-conformidade'));
const ProductionOrderList = lazy(() => import('root-pcp/ordemdeproducao/views/ordem-producao'));
const EditProductionOrder = lazy(() => import('root-pcp/ordemdeproducao/views/editar-ordem-producao'));
const ProductionOrderBatchList = lazy(() => import('root-pcp/ordemproducaolote/views/ordem-producao-lote'));
const EditProductionOrderBatch = lazy(() => import('root-pcp/ordemproducaolote/views/editar-ordem-producao-lote'));
const InputRequestList = lazy(() => import('root-pcp/pedidosinsumo/views/pedidos-insumo'));
const EditInputRequest = lazy(() => import('root-pcp/pedidosinsumo/views/editar-pedido-insumo'));
const ProductionPriorityProfileList = lazy(() => import('root-pcp/perfilprioridadeproducao/views/perfil-prioridade-producao'));
const EditProductionPriorityProfile = lazy(() => import('root-pcp/perfilprioridadeproducao/views/editar-perfil-prioridade-producao'));
const PlantProductionLineList = lazy(() => import('root-pcp/plantaslinhaproducao/views/planta-linha-producao'));
const EditPlantProductionLine = lazy(() => import('root-pcp/plantaslinhaproducao/views/editar-linha-producao'));
const AnalysisTypeList = lazy(() => import('root-pcp/tipoanalise/views/tipo-analise'));
const EditAnalysisType = lazy(() => import('root-pcp/tipoanalise/views/editar-tipo-analise'));
const CancellationTypeList = lazy(() => import('root-pcp/tipocancelamento/views/tipo-cancelamento'));
const EditCancellationType = lazy(() => import('root-pcp/tipocancelamento/views/editar-tipo-cancelamento'));
const CharacteristicTypeList = lazy(() => import('root-pcp/tipocaracteristica/views/tipo-caracteristica'));
const EditCharacteristicType = lazy(() => import('root-pcp/tipocaracteristica/views/editar-tipo-caracteristica'));
const NonComplianceTypeList = lazy(() => import('root-pcp/tiponaoconformidade/views/tipo-nao-conformidade'));
const EditNonComplianceType = lazy(() => import('root-pcp/tiponaoconformidade/views/editar-nao-conformidade'));
const QualityInspectionTestList = lazy(() => import('root-pcp/tipotesteinspecaoqualidade/view/testeinspecaoqualidade'));
const EditQualityInspectionTest = lazy(() => import('root-pcp/tipotesteinspecaoqualidade/view/editartesteinspecaoqualidade'));
const ShiftList = lazy(() => import('root-pcp/turno/views/tabela-turno'));
const EditShift = lazy(() => import('root-pcp/turno/views/editar-turno'));
const ListingProductionSchedule = lazy(() => import('root-pcp/programacaoproducao/views/prog-producao'));
const CRUDProductionSchedule = lazy(() => import('root-pcp/programacaoproducao/views/editar-prog-producao'));
const ListingProductionCycle = lazy(() => import('root-pcp/cicloproducao/views/ciclo-producao'));
const CRUDProductionCycle = lazy(() => import('root-pcp/cicloproducao/views/editar-ciclo-producao'));

const StorageList = lazy(() => import('root-me/armazem/views/armazem'));
const EditStorage = lazy(() => import('root-me/armazem/views/editar-armazem'));
const BalanceList = lazy(() => import('root-me/balanco/views/balanco'));
const EditBalance = lazy(() => import('root-me/balanco/views/editar-balanco'));
const StockConfiguration = lazy(() => import('root-me/configuracao/views/configuracao'));
const StockCountingList = lazy(() => import('root-me/contagemestoque/views/contagem-estoque'));
const EditStockCounting = lazy(() => import('root-me/contagemestoque/views/editar-contagem-estoque'));
const ItemMovimentList = lazy(() => import('root-me/dashboardmovimentacaoitem/views/movimentacao-item'));
const DashboardItemMoviment = lazy(() => import('root-me/dashboardmovimentacaoitem/views/dashboard-movimentacao-item'));
const StockAddressingList = lazy(() => import('root-me/enderecamentoestoque/views/enderecamento-estoque'));
const EditStockAddressing = lazy(() => import('root-me/enderecamentoestoque/views/editar-enderecamento-estoque'));
const StockList = lazy(() => import('root-me/estoque/views/estoque'));
const EditStock = lazy(() => import('root-me/estoque/views/editar-estoque'));
const MovementStatementList = lazy(() => import('root-me/extratomovimentacao/views/extrato-movimentacao'));
const ViewMovementStatement = lazy(() => import('root-me/extratomovimentacao/views/view-extrato-movimentacao'));
const StockReleaseList = lazy(() => import('root-me/lancamentoavulsoestoque/views/lancamento-avulso-estoque'));
const EditStockRelease = lazy(() => import('root-me/lancamentoavulsoestoque/views/editar-lancamento-avulso-estoque'));
const StockBalanceList = lazy(() => import('root-me/saldoestoque/views/saldo-estoque'));
const ViewStockBalance = lazy(() => import('root-me/saldoestoque/views/view-saldo-estoque'));
const ItemsTransferList = lazy(() => import('root-me/transferenciaitens/views/transferenciaitens'));
const EditItemsTransfer = lazy(() => import('root-me/transferenciaitens/views/editar-transferencia-itens'));

const ActionPerformedList = lazy(() => import('root-os/acaorealizada/views/acao-realizada'));
const EditActionPerformed = lazy(() => import('root-os/acaorealizada/views/editar-acao-realizada'));
const ServiceSchedulingList = lazy(() => import('root-os/agendamentoservico/views/agendamento-servico'));
const EditServiceScheduling = lazy(() => import('root-os/agendamentoservico/views/editar-agendamento-servico'));
const ServiceOrderSchedule = lazy(() => import('root-os/agendaordemservico/views/agenda-ordem-servico'));
const EventRegistrationSchedule = lazy(() => import('root-os/agendaordemservico/views/cadastro-evento'));
const EventDetails = lazy(() => import('root-os/agendaordemservico/views/detalhes-evento-agenda'));
const ServiceSchedulingAnswers = lazy(() => import('root-os/agendamentoservico/views/resposta-agendamento-servico'));
const ViewSchedulingAnswers = lazy(() => import('root-os/agendamentoservico/views/visualizar-resposta-agendamento'));
const ServiceAuthorizationList = lazy(() => import('root-os/autorizarServico/views/autorizacao-servico'));
const EditServiceAuthorization = lazy(() => import('root-os/autorizarServico/views/editar-autorizacao-servico'));
const ServiceRegistrationList = lazy(() => import('root-os/cadastroServico/views/cadastro-servico'));
const ServiceRegistration = lazy(() => import('root-os/cadastroServico/views/editar-cadastro-servico'));
const OSFormConfigurationList = lazy(() => import('root-os/configuracao/views/configuracao-questionario-os'));
const EditOSFormConfiguration = lazy(() => import('root-os/configuracao/views/editar-configuracao-questionario-os'));
const DashboardOS = lazy(() => import('root-os/dashbordos/views/dashboard'));
const CheckoutFormAnswersList = lazy(() => import('root-os/respostascheckout/views/respotas-check-out'));
const ViewCheckoutFormAnswers = lazy(() => import('root-os/respostascheckout/views/visualizar-respostas'));
const ServiceOrderRequestList = lazy(() => import('root-os/solicitacaoordemservico/views/solicitacao-ordem-servico'));
const EditServiceOrderRequest = lazy(() => import('root-os/solicitacaoordemservico/views/editar-solicitacao-ordem-servico'));
const ServiceTypeList = lazy(() => import('root-os/tiposervico/views/tipo-servico'));
const EditServiceType = lazy(() => import('root-os/tiposervico/views/editar-tipo-servico'));

const PDFView = lazy(() => import('./pdfviewer/pdf-viewer'));

const BankAccountList = lazy(() => import('../financeiro/contabancaria/views/conta-bancaria'));
const EditBankAccount = lazy(() => import('../financeiro/contabancaria/views/editar-conta-bancaria'));
const FlagCardList = lazy(() => import('../financeiro/bandeiracartao/views/bandeira-cartao'));
const EditFlagCard = lazy(() => import('../financeiro/bandeiracartao/views/editar-bandeira-cartao'));
const BankList = lazy(() => import('../financeiro/instituicaobancaria/views/instituicoes-bancarias'));
const EditBank = lazy(() => import('../financeiro/instituicaobancaria/views/editar-instituicao-bancaria'));
const CardOperatorList = lazy(() => import('../financeiro/operadoracartao/views/operadoras-cartao'));
const EditCardOperator = lazy(() => import('../financeiro/operadoracartao/views/editar-operadora-cartao'));
const BankRemittanceList = lazy(() => import('../financeiro/remessabancaria/views/remessa-bancaria'));
const EditBankRemittance = lazy(() => import('../financeiro/remessabancaria/views/editar-remessa-bancaria'));

const PaymentMethodList = lazy(() => import('./modulofaturamento/formapagamento/forma-pagamento'));
const EditPaymentMethod = lazy(() => import('./modulofaturamento/formapagamento/editar-forma-pagamento'));
const PaymentConditionList = lazy(() => import('./modulofaturamento/condicaopagamento/condicao-pagamento'));
const EditPaymentCondition = lazy(() => import('./modulofaturamento/condicaopagamento/editar-condicao-pagamento'));
const MethodConditionList = lazy(() => import('./modulofaturamento/formaxcondicao/formaxcondicao'));
const EditMethodCondition = lazy(() => import('./modulofaturamento/formaxcondicao/editar-forma-condicao'));

const FormList = lazy(() => import('../qa/questionario/views/questionario'));
const EditForm = lazy(() => import('../qa/questionario/views/editar-questionario'));
const AnswersList = lazy(() => import('../qa/respostas/views/respostas'));
const ViewAnswers = lazy(() => import('../qa/respostas/views/visualizar-respostas'));

const PriceChainList = lazy(() => import('../precos/cadeiaprecos/views/cadeia-precos'));
const EditPriceChain = lazy(() => import('../precos/cadeiaprecos/views/editar-cadeia-preco'));
const PricingSetup = lazy(() => import('../precos/configuracaoprecos/views/configuracao-preco'));

const SalesOrderList = lazy(() => import('./modulocomercial/pedidovenda/pedidovenda'));
const EditSalesOrder = lazy(() => import('./modulocomercial/pedidovenda/editar-pedido'));
const SalesOrderApprovalList = lazy(() => import('root-mv/autorizacaopedidovenda/views/autorizacao-pedido-vendas'));
const EditSalesOrderApproval = lazy(() => import('root-mv/autorizacaopedidovenda/views/editar-autorizacao-pedido'));

const MerchandiseReceptionList = lazy(() => import('root-ml/recepcaomercadoria/views/recepcao-mercadoria'));
const EditMerchandiseReception = lazy(() => import('root-ml/recepcaomercadoria/views/editar-recepcao-mercadoria'));

const Host = lazy(() => import('../rpflex/host/views/host'));
const RpflexCredential = lazy(() => import('../rpflex/credencial/views/credencial-rpflex'));
const StockBacklogList = lazy(() => import('root-rpflex/pendenciaestoque/views/pendencia-estoque'));

const FinalidadeCompra = lazy(() => import('root-mc/finalidadecompra/views/finalidade-compra'))
const FinalidadeCompraEdit = lazy(() => import('root-mc/finalidadecompra/views/edit-finalidade-compra'))
const CentroResultados = lazy(() => import('root-mc/centroresultados/views/centro-resultados'))
const EditCentroResultados = lazy(() => import('root-mc/centroresultados/views/edit-centro-resultados'))
const SolicitacaoCompra = lazy(() => import('root-mc/solicitacaocompra/views/solicitacao-compra'))
const EditSolicitacaoCompra = lazy(() => import('root-mc/solicitacaocompra/views/edit-solicitacao-compra'))
const ApprovePurchaseRequest = lazy(() => import('root-mc/autorizarsolicitacaocompra/views/autorizacao-solicitacao-compras'));
const EditApprovePurchaseRequest = lazy(() => import('root-mc/autorizarsolicitacaocompra/views/editar-autorizacao-compra'));
const PurchaseQuoteList = lazy(() => import('root-mc/cotacaocompras/views/cotacao-compras'));
const EditPurchaseQuote = lazy(() => import('root-mc/cotacaocompras/views/editar-cotacao-compra'))
const PurchaseRequestList = lazy(() => import('root-mc/pedidocompras/views/pedidoscompra'))
const CRUDPurchaseRequest = lazy(() => import('root-mc/pedidocompras/views/editar-pedido-compra'))
const Orcamento = lazy(() => import('root-mc/orcamento/views/orcamento'));
const EditOrcamento = lazy(() => import('root-mc/orcamento/views/editar-orcamento'));
const LocalEntrega = lazy(() => import('root-mc/localentrega/views/local-entrega'));
const EditLocalEntrega = lazy(() => import('root-mc/localentrega/views/editar-local-entrega'));
const ListingSalesGoals = lazy(() => import('root-mc/metavendas/views/metavendas'));
const CRUDSalesGoals = lazy(() => import('root-mc/metavendas/views/editar-meta-vendas'));
const ApprovePurchaseOrder = lazy(() => import('root-mc/aprovacao/pedidocompra/pages/lista-aprovacao-pedidos'));
const CRUDApprovePurchaseOrder = lazy(() => import('root-mc/aprovacao/pedidocompra/pages/crud-aprovacao-pedido'));

export type ScreenRoutesType = {
  path: string;
  exactPath?: boolean;
  component: (props: any) => any;
};

/**
 * Constante com as definições das rotas das Screens/Views/Telas da aplicação
 */
const viewsRoutes: ScreenRoutesType[] = [
  {
    path: '/',
    exactPath: true,
    component: Dashboard,
  },
  {
    path: 'empresa',
    exactPath: true,
    component: CompanyList,
  },
  {
    path: 'empresa/novo',
    component: EditCompany,
  },
  {
    path: 'empresa/editar/:id',
    exactPath: true,
    component: EditCompany,
  },
  {
    path: 'filial',
    exactPath: true,
    component: BranchList,
  },
  {
    path: 'filial/novo',
    component: EditBranch,
  },
  {
    path: 'filial/editar/:id',
    exactPath: true,
    component: EditBranch,
  },
  {
    path: 'pessoas/colaborador',
    exactPath: true,
    component: CollaboratorList,
  },
  {
    path: 'pessoas/colaborador/novo',
    component: EditCollaborator,
  },
  {
    path: 'pessoas/colaborador/editar/:id',
    component: EditCollaborator,
  },
  {
    path: 'produto/unidademedida',
    exactPath: true,
    component: ProductMeasurementUnitList,
  },
  {
    path: 'produto/unidademedida/novo',
    component: EditProductMeasurementUnit,
  },
  {
    path: 'produto/unidademedida/editar/:id',
    component: EditProductMeasurementUnit,
  },
  {
    path: 'produto/marca',
    exactPath: true,
    component: ProductBrandList,
  },
  {
    path: 'produto/marca/novo',
    component: EditProductBrand,
  },
  {
    path: 'produto/marca/editar/:id',
    component: EditProductBrand,
  },
  {
    path: 'produto/etapa',
    exactPath: true,
    component: ProductStageList,
  },
  {
    path: 'produto/etapa/novo',
    component: EditProductStage,
  },
  {
    path: 'produto/etapa/editar/:id',
    component: EditProductStage,
  },
  {
    path: 'tipo-de-item',
    component: ItemTypeList,
    exactPath: true,
  },
  {
    path: 'tipo-de-item/novo',
    component: EditItemType,
  },
  {
    path: 'tipo-de-item/editar/:id',
    component: EditItemType,
  },
  {
    path: 'ncm',
    component: NCMList,
    exactPath: true,
  },
  {
    path: 'ncm/novo',
    component: EditNCM,
  },
  {
    path: 'ncm/editar/:id',
    component: EditNCM,
  },
  {
    path: 'pessoas/fornecedor',
    component: SupplierList,
    exactPath: true,
  },
  {
    path: 'pessoas/fornecedor/novo',
    component: EditSupplier,
  },
  {
    path: 'pessoas/fornecedor/editar/:id',
    component: EditSupplier,
  },
  {
    path: 'pessoas/cliente',
    component: ClientList,
    exactPath: true,
  },
  {
    path: 'pessoas/cliente/novo',
    component: EditClient,
  },
  {
    path: 'pessoas/cliente/editar/:id',
    component: EditClient,
  },
  {
    path: 'pessoas/representante',
    component: RepresentativeList,
    exactPath: true,
  },
  {
    path: 'pessoas/representante/novo',
    component: EditRepresentative,
  },
  {
    path: 'pessoas/representante/editar/:id',
    component: EditRepresentative,
  },
  {
    path: 'pessoas/vendedor',
    component: SellerList,
    exactPath: true,
  },
  {
    path: 'pessoas/vendedor/novo',
    component: EditSeller,
  },
  {
    path: 'pessoas/vendedor/editar/:id',
    component: EditSeller,
  },
  {
    path: 'setor',
    component: SectorList,
    exactPath: true,
  },
  {
    path: 'setor/novo',
    component: EditSector,
  },
  {
    path: 'setor/editar/:id',
    component: EditSector,
  },
  {
    path: 'maquinario',
    component: MachineryList,
    exactPath: true,
  },
  {
    path: 'maquinario/novo',
    component: EditMachinery,
  },
  {
    path: 'maquinario/editar/:id',
    component: EditMachinery,
  },
  {
    path: 'perfil-prioridade-producao',
    component: ProductionPriorityProfileList,
  },
  {
    path: 'perfil-prioridade-producao/novo',
    component: EditProductionPriorityProfile,
  },
  {
    path: 'perfil-prioridade-producao/editar/:id',
    component: EditProductionPriorityProfile,
  },
  {
    path: 'produto/corproduto',
    component: ProductColorList,
    exactPath: true,
  },
  {
    path: 'produto/corproduto/novo',
    component: EditProductColor,
  },
  {
    path: 'produto/corproduto/editar/:id',
    component: EditProductColor,
  },
  {
    path: 'regime-tributario',
    component: TaxRegimeList,
    exactPath: true,
  },
  {
    path: 'regime-tributario/novo',
    component: EditTaxRegime,
  },
  {
    path: 'regime-tributario/editar/:id',
    component: EditTaxRegime,
  },
  {
    path: 'produto/produto',
    component: ProductList,
    exactPath: true,
  },
  {
    path: 'produto/produto/novo',
    component: EditProduct,
  },
  {
    path: 'produto/produto/editar/:id',
    component: EditProduct,
  },
  {
    path: 'enderecamento-estoque',
    component: StockAddressingList,
    exactPath: true,
  },
  {
    path: 'enderecamento-estoque/novo',
    component: EditStockAddressing,
  },
  {
    path: 'enderecamento-estoque/editar/:id',
    component: EditStockAddressing,
  },
  {
    path: 'armazem',
    component: StorageList,
    exactPath: true,
  },
  {
    path: 'armazem/novo',
    component: EditStorage,
  },
  {
    path: 'armazem/editar/:id',
    component: EditStorage,
  },
  {
    path: 'estoque',
    component: StockList,
    exactPath: true,
  },
  {
    path: 'estoque/novo',
    component: EditStock,
  },
  {
    path: 'estoque/editar/:id',
    component: EditStock,
  },
  {
    path: 'consulta-saldo',
    component: StockBalanceList,
    exactPath: true,
  },
  {
    path: 'consulta-saldo/view/:id',
    component: ViewStockBalance,
  },
  {
    path: 'produto/estrutura-mercadologica/divisao',
    component: ProductDivisionList,
    exactPath: true,
  },
  {
    path: 'produto/estrutura-mercadologica/divisao/novo',
    component: EditProductDivision
  },
  {
    path: 'produto/estrutura-mercadologica/divisao/editar/:id',
    component: EditProductDivision,
  },
  {
    path: 'produto/estrutura-mercadologica/secao',
    component: ProductSectionList,
    exactPath: true,
  },
  {
    path: 'produto/estrutura-mercadologica/secao/novo',
    component: EditProductSection
  },
  {
    path: 'produto/estrutura-mercadologica/secao/editar/:id',
    component: EditProductSection,
  },
  {
    path: 'produto/estrutura-mercadologica/subgrupo',
    component: ProductSubgroupList,
    exactPath: true,
  },
  {
    path: 'produto/estrutura-mercadologica/subgrupo/novo',
    component: EditProductSubgroup
  },
  {
    path: 'produto/estrutura-mercadologica/subgrupo/editar/:id',
    component: EditProductSubgroup,
  },
  {
    path: 'produto/estrutura-mercadologica/grupo',
    component: ProductGroupList,
    exactPath: true,
  },
  {
    path: 'produto/estrutura-mercadologica/grupo/novo',
    component: EditProductGroup
  },
  {
    path: 'produto/estrutura-mercadologica/grupo/editar/:id',
    component: EditProductGroup,
  },
  {
    path: 'cnae',
    component: CNAESList,
    exactPath: true,
  },
  {
    path: 'cnae/editar/:id',
    component: EditCNAE,
  },
  {
    path: 'forma-pagamento',
    component: PaymentMethodList,
    exactPath: true
  },
  {
    path: 'forma-pagamento/editar/:id',
    component: EditPaymentMethod,
  },
  {
    path: 'condicao-pagamento',
    component: PaymentConditionList,
    exactPath: true
  },
  {
    path: 'condicao-pagamento/novo',
    component: EditPaymentCondition,
  },
  {
    path: 'condicao-pagamento/editar/:id',
    component: EditPaymentCondition,
  },
  {
    path: 'forma-pagamento-condicao-pagamento',
    component: MethodConditionList,
    exactPath: true
  },
  {
    path: 'forma-pagamento-condicao-pagamento/editar/:id',
    component: EditMethodCondition,
  },
  {
    path: 'pedido-venda',
    component: SalesOrderList,
    exactPath: true
  },
  {
    path: 'pedido-venda/novo',
    component: EditSalesOrder,
  },
  {
    path: 'pedido-venda/editar/:id',
    component: EditSalesOrder,
  },
  {
    path: 'tipo-servico-prestado',
    component: ServiceTypeList,
    exactPath: true
  },
  {
    path: 'tipo-servico-prestado/novo',
    component: EditServiceType
  },
  {
    path: 'tipo-servico-prestado/editar/:id',
    component: EditServiceType
  },
  {
    path: 'autorizacao-ordem-servico',
    component: ServiceAuthorizationList,
    exactPath: true
  },
  {
    path: 'autorizacao-ordem-servico/editar/:id',
    component: EditServiceAuthorization
  },
  {
    path: 'acao-realizada',
    component: ActionPerformedList,
    exactPath: true
  },
  {
    path: 'acao-realizada/novo',
    component: EditActionPerformed
  },
  {
    path: 'acao-realizada/editar/:id',
    component: EditActionPerformed
  },
  {
    path: 'autorizacao-pedido-venda',
    component: SalesOrderApprovalList,
    exactPath: true
  },
  {
    path: 'autorizacao-pedido-venda/editar/:id',
    component: EditSalesOrderApproval
  },
  {
    path: 'solicitacao-ordem-servico',
    component: ServiceOrderRequestList,
    exactPath: true
  },
  {
    path: 'solicitacao-ordem-servico/novo',
    component: EditServiceOrderRequest
  },
  {
    path: 'solicitacao-ordem-servico/editar/:id',
    component: EditServiceOrderRequest
  },
  {
    path: 'agenda-ordem-servico',
    component: ServiceOrderSchedule,
    exactPath: true
  },
  {
    path: 'agenda-ordem-servico/detalhes/:id',
    component: EventDetails
  },
  {
    path: 'agenda-ordem-servico/novo',
    component: EventRegistrationSchedule
  },
  {
    path: 'agenda-ordem-servico/editar/:id',
    component: EventRegistrationSchedule
  },
  {
    path: 'servico-prestado',
    component: ServiceRegistrationList,
    exactPath: true
  },
  {
    path: 'servico-prestado/novo',
    component: ServiceRegistration
  },
  {
    path: 'servico-prestado/editar/:id',
    component: ServiceRegistration,
  },
  {
    path: 'importar-nota-fiscal',
    component: InvoiceList,
    exactPath: true
  },
  {
    path: 'importar-nota-fiscal/novo',
    component: ImportInvoice
  },
  {
    path: 'importar-nota-fiscal/editar/:id',
    component: ImportInvoice
  },
  {
    path: 'agendamento-servico',
    component: ServiceSchedulingList,
    exactPath: true
  },
  {
    path: 'agendamento-servico/novo',
    component: EditServiceScheduling
  },
  {
    path: 'agendamento-servico/editar/:id',
    component: EditServiceScheduling
  },
  {
    path: 'agendamento-servico/resposta/:id',
    component: ServiceSchedulingAnswers
  },
  {
    path: 'agendamento-servico/resposta/:id/visualizar/:idResposta',
    component: ViewSchedulingAnswers
  },
  {
    path: 'dashboard',
    component: DashboardOS,
    exactPath: true
  },
  {
    path: 'figura-fiscal-piscofins',
    component: PISCOFINSTaxFigureList,
    exactPath: true
  },
  {
    path: 'figura-fiscal-piscofins/editar/:id',
    component: EditPISCOFINSTaxFigure,
  },
  {
    path: 'figura-fiscal-piscofins/novo',
    component: EditPISCOFINSTaxFigure
  },
  {
    path: 'tributacao-participante',
    component: ParticipantTaxationList,
    exactPath: true
  },
  {
    path: 'tributacao-participante/view/:id',
    component: EditParticipantTaxation
  },
  {
    path: 'cadastro-mensagem-fiscal',
    component: FiscalMessageList,
    exactPath: true
  },
  {
    path: 'cadastro-mensagem-fiscal/novo',
    component: EditFiscalMessage,
  },
  {
    path: 'cadastro-mensagem-fiscal/editar/:id',
    component: EditFiscalMessage,
  },
  {
    path: 'cod-beneficio-fiscal',
    component: TaxBenefitCodeList,
    exactPath: true
  },
  {
    path: 'cod-beneficio-fiscal/view/:id',
    component: ViewTaxBenefitCode
  },
  {
    path: 'inserir-cest',
    component: InsertCEST,
    exactPath: true
  },
  {
    path: 'inserir-cest/view/:id',
    component: ViewInsertCEST
  },
  {
    path: 'figura-fiscal-iss',
    component: TaxFiguresISSList,
    exactPath: true
  },
  {
    path: 'figura-fiscal-iss/novo',
    component: EditTaxFiguresISS
  },
  {
    path: 'figura-fiscal-iss/editar/:id',
    component: EditTaxFiguresISS
  },
  {
    path: 'dashboard-ordem-producao',
    component: ProductionOrderDashboard,
    exactPath: true
  },
  {
    path: 'ordem-producao',
    component: ProductionOrderList,
    exactPath: true
  },
  {
    path: 'ordem-producao/novo',
    component: EditProductionOrder
  },
  {
    path: 'ordem-producao/editar/:id',
    component: EditProductionOrder
  },
  {
    path: 'jornada-trabalho',
    component: WorkingDayList,
    exactPath: true
  },
  {
    path: 'jornada-trabalho/novo',
    component: EditWorkingDay
  },
  {
    path: 'jornada-trabalho/editar/:id',
    component: EditWorkingDay
  },
  {
    path: 'planta-linha-producao',
    component: PlantProductionLineList,
    exactPath: true
  },
  {
    path: 'planta-linha-producao/novo',
    component: EditPlantProductionLine
  },
  {
    path: 'planta-linha-producao/editar/:id',
    component: EditPlantProductionLine
  },
  {
    path: 'recepcao-mercadoria',
    component: MerchandiseReceptionList,
    exactPath: true
  },
  {
    path: 'recepcao-mercadoria/novo',
    component: EditMerchandiseReception
  },
  {
    path: 'recepcao-mercadoria/editar/:id',
    component: EditMerchandiseReception
  },
  {
    path: 'laudos-qualidade',
    component: QualityReportList,
    exactPath: true
  },
  {
    path: 'laudos-qualidade/novo',
    component: EditQualityReport
  },
  {
    path: 'laudos-qualidade/editar/:id',
    component: EditQualityReport
  },
  {
    path: 'insumo-ordem-producao',
    component: InputRequestList,
    exactPath: true
  },
  {
    path: 'insumo-ordem-producao/novo',
    component: EditInputRequest,
  },
  {
    path: 'insumo-ordem-producao/editar/:id',
    component: EditInputRequest
  },
  {
    path: 'dashboard-movimentacao-item',
    component: ItemMovimentList,
    exactPath: true
  },
  {
    path: 'dashboard-movimentacao-item/:id',
    component: DashboardItemMoviment,
  },
  {
    path: 'transferencia-itens-estoque',
    component: ItemsTransferList,
    exactPath: true
  },
  {
    path: 'transferencia-itens-estoque/novo',
    component: EditItemsTransfer
  },
  {
    path: 'transferencia-itens-estoque/editar/:id',
    component: EditItemsTransfer
  },
  {
    path: 'importar-conhecimento-transporte-eletronico',
    component: TransportList,
    exactPath: true,
  },
  {
    path: 'importar-conhecimento-transporte-eletronico/novo',
    component: EditTransport
  },
  {
    path: 'importar-conhecimento-transporte-eletronico/editar/:id',
    component: EditTransport
  },
  {
    path: 'natureza-da-operacao',
    component: NatureOperationList,
    exactPath: true
  },
  {
    path: 'natureza-da-operacao/editar/:id',
    component: EditNatureOperation
  },
  {
    path: 'natureza-da-operacao/novo',
    component: EditNatureOperation
  },
  {
    path: 'codigo-de-situacao-tributaria',
    component: CSTList,
    exactPath: true
  },
  {
    path: 'codigo-de-situacao-tributaria/editar/:id',
    component: EditCST
  },
  {
    path: 'cadastro-de-operacao',
    component: OperationList,
    exactPath: true
  },
  {
    path: 'cadastro-de-operacao/editar/:id',
    component: EditOperation
  },
  {
    path: 'cadastro-de-operacao/novo',
    component: EditOperation
  },
  {
    path: 'cadastro-codigo-servicos',
    component: ServiceCodeList,
    exactPath: true
  },
  {
    path: 'cadastro-codigo-servicos/:id',
    component: ViewServiceCode
  },
  {
    path: 'tabela-ibpt-por-ncm',
    component: IBPTNCMList,
    exactPath: true
  },
  {
    path: 'tabela-ibpt-por-ncm/:id',
    component: ViewIBPTNCM
  },
  {
    path: 'inserir-csosn',
    component: CSOSNList,
  },
  {
    path: 'inserir-csosn/:id',
    component: ViewCSOSN,
  },
  {
    path: 'figura-fiscal-icms',
    component: ICMSTaxFigureList,
    exactPath: true
  },
  {
    path: 'figura-fiscal-icms/novo',
    component: EditICMSTaxFigure
  },
  {
    path: 'figura-fiscal-icms/editar/:id',
    component: EditICMSTaxFigure
  },
  {
    path: 'origem-mercadoria',
    component: MerchandiseOriginList,
    exactPath: true
  },
  {
    path: 'origem-mercadoria/item/:id',
    component: ViewMerchandiseOrigin
  },
  {
    path: 'natureza-credito-pis-cofins',
    component: NatureCreditPISCOFINSList,
    exactPath: true
  },
  {
    path: 'natureza-credito-pis-cofins/novo',
    component: EditNatureCreditPISCOFINS
  },
  {
    path: 'natureza-credito-pis-cofins/editar/:id',
    component: EditNatureCreditPISCOFINS
  },
  {
    path: 'genero-item',
    component: ItemGenreList,
    exactPath: true
  },
  {
    path: 'genero-item/item/:id',
    component: ViewItemGenre
  },
  {
    path: 'codigo-fiscal-de-operacao-e-prestacao',
    component: CFOPList,
    exactPath: true
  },
  {
    path: 'codigo-fiscal-de-operacao-e-prestacao/editar/:id',
    component: EditCFOP
  },
  {
    path: 'codigo-fiscal-de-operacao-e-prestacao/novo',
    component: EditCFOP
  },
  {
    path: 'caracteristica-qualidade',
    component: QualityCharacteristicList,
    exactPath: true
  },
  {
    path: 'caracteristica-qualidade/editar/:id',
    component: EditQualityCharacteristic
  },
  {
    path: 'caracteristica-qualidade/novo',
    component: EditQualityCharacteristic
  },
  {
    path: 'tipo-caracteristica',
    component: CharacteristicTypeList,
    exactPath: true
  },
  {
    path: 'tipo-caracteristica/editar/:id',
    component: EditCharacteristicType
  },
  {
    path: 'prioridade-de-ordem-de-producao',
    component: PriorityList,
    exactPath: true
  },
  {
    path: 'prioridade-de-ordem-de-producao/editar/:id',
    component: EditPriority
  },
  {
    path: 'gestao-producao',
    component: ProductionManagementList,
    exactPath: true
  },
  {
    path: 'gestao-producao/editar/:id',
    component: EditProductionManagement
  },
  {
    path: 'gestao-producao/novo',
    component: EditProductionManagement
  },
  {
    path: 'nao-conformidade',
    component: NonComplianceList,
    exactPath: true
  },
  {
    path: 'nao-conformidade/ordem-producao/:id/apontamentos',
    component: NonComplianceAppointment
  },
  {
    path: 'nao-conformidade/apontamento/:id/editar/:id',
    component: EditNonCompliance
  },
  {
    path: 'tipo-teste-inspecao-qualidade',
    component: QualityInspectionTestList,
    exactPath: true
  },
  {
    path: 'tipo-teste-inspecao-qualidade/novo',
    component: EditQualityInspectionTest
  },
  {
    path: 'tipo-teste-inspecao-qualidade/editar/:id',
    component: EditQualityInspectionTest
  },
  {
    path: 'inspecao-qualidade',
    component: QualityInspectionList,
    exactPath: true
  },
  {
    path: 'inspecao-qualidade/editar/:id',
    component: EditQualityInspection
  },
  {
    path: 'apontamentos',
    component: AppointmentsList,
    exactPath: true
  },
  {
    path: 'apontamentos/editar/:id',
    component: EditAppointments
  },
  {
    path: 'apontamentos/editar/:id/:idApontamento',
    component: EditAppointments
  },
  {
    path: 'turno',
    component: ShiftList,
    exactPath: true
  },
  {
    path: 'turno/editar/:id',
    component: EditShift
  },
  {
    path: 'turno/novo',
    component: EditShift
  },
  {
    path: 'lote-producao',
    component: ProductionBatchList,
    exactPath: true
  },
  {
    path: 'lote-producao/editar/:id',
    component: EditProductionBatch
  },
  {
    path: 'lote-producao/novo',
    component: EditProductionBatch
  },
  {
    path: 'analises-para-inspecao-e-qualidade',
    component: AnalysisTypeList,
    exactPath: true
  },
  {
    path: 'analises-para-inspecao-e-qualidade/editar/:id',
    component: EditAnalysisType
  },
  {
    path: 'analises-para-inspecao-e-qualidade/novo',
    component: EditAnalysisType
  },
  {
    path: 'especificacao-de-analise-para-inspecao-e-qualidade',
    component: SpecificationList,
    exactPath: true
  },
  {
    path: 'especificacao-de-analise-para-inspecao-e-qualidade/editar/:id',
    component: EditSpecification
  },
  {
    path: 'especificacao-de-analise-para-inspecao-e-qualidade/novo',
    component: EditSpecification
  },
  {
    path: 'alergenos',
    component: AllergensList,
    exactPath: true
  },
  {
    path: 'tipo-nao-conformidade',
    component: NonComplianceTypeList,
    exactPath: true
  },
  {
    path: 'tipo-nao-conformidade/editar/:id',
    component: EditNonComplianceType,
  },
  {
    path: 'tipo-nao-conformidade/novo',
    component: EditNonComplianceType,
  },
  {
    path: 'contagem-estoque',
    component: StockCountingList,
    exactPath: true
  },
  {
    path: 'contagem-estoque/novo',
    component: EditStockCounting,
  },
  {
    path: 'contagem-estoque/novo/:idbalanco',
    component: EditStockCounting,
  },
  {
    path: 'contagem-estoque/editar/:id',
    component: EditStockCounting,
  },
  {
    path: 'lancamento-avulso-estoque',
    component: StockReleaseList,
    exactPath: true
  },
  {
    path: 'lancamento-avulso-estoque/editar/:id',
    component: EditStockRelease,
  },
  {
    path: 'lancamento-avulso-estoque/novo',
    component: EditStockRelease
  },
  {
    path: 'configuracao-estoque',
    component: StockConfiguration,
  },
  {
    path: 'balanco',
    component: BalanceList,
    exactPath: true
  },
  {
    path: 'balanco/editar/:id',
    component: EditBalance,
  },
  {
    path: 'balanco/novo',
    component: EditBalance,
  },
  {
    path: 'configuracao-precos',
    component: PricingSetup,
    exactPath: true
  },
  {
    path: 'cadeia-precos',
    component: PriceChainList,
    exactPath: true
  },
  {
    path: 'cadeia-precos/editar/:id',
    component: EditPriceChain,
  },
  {
    path: 'cadeia-precos/novo',
    component: EditPriceChain,
  },
  {
    path: 'respostas-check-out',
    component: CheckoutFormAnswersList,
    exactPath: true
  },
  {
    path: 'visualizar-respostas-check-out/:id',
    component: ViewCheckoutFormAnswers,
  },
  {
    path: 'questionario',
    component: FormList,
    exactPath: true
  },
  {
    path: 'questionario/editar/:id',
    component: EditForm
  },
  {
    path: 'questionario/novo',
    component: EditForm
  },
  {
    path: 'configuracao-questionario-os',
    component: OSFormConfigurationList,
  },
  {
    path: 'configuracao-questionario-os/editar/:id',
    component: EditOSFormConfiguration,
  },
  {
    path: 'instituicoes-bancarias',
    component: BankList,
    exactPath: true
  },
  {
    path: 'instituicoes-bancarias/editar/:id',
    component: EditBank
  },
  {
    path: 'instituicoes-bancarias/novo',
    component: EditBank
  },
  {
    path: 'bandeira-cartao',
    component: FlagCardList,
    exactPath: true
  },
  {
    path: 'bandeira-cartao/editar/:id',
    component: EditFlagCard
  },
  {
    path: 'bandeira-cartao/novo',
    component: EditFlagCard
  },
  {
    path: 'conta-bancaria',
    component: BankAccountList,
    exactPath: true
  },
  {
    path: 'conta-bancaria/editar/:id',
    component: EditBankAccount
  },
  {
    path: 'conta-bancaria/novo',
    component: EditBankAccount
  },
  {
    path: 'configuracao-remessa-bancaria',
    component: BankRemittanceList,
    exactPath: true
  },
  {
    path: 'configuracao-remessa-bancaria/editar/:id',
    component: EditBankRemittance
  },
  {
    path: 'configuracao-remessa-bancaria/novo',
    component: EditBankRemittance
  },
  {
    path: 'operadoras-cartao',
    component: CardOperatorList,
    exactPath: true
  },
  {
    path: 'operadoras-cartao/editar/:id',
    component: EditCardOperator
  },
  {
    path: 'operadoras-cartao/novo',
    component: EditCardOperator
  },
  {
    path: '/pdf',
    exactPath: true,
    component: PDFView,
  },
  {
    path: '/pdf/:id',
    exactPath: true,
    component: PDFView,
  },
  {
    path: '/respostas',
    exactPath: true,
    component: AnswersList,
  },
  {
    path: '/respostas/:id',
    component: ViewAnswers,
  },
  {
    path: 'extrato-movimentacao',
    component: MovementStatementList,
    exactPath: true,
  },
  {
    path: 'extrato-movimentacao/view/:id',
    component: ViewMovementStatement,
  },
  {
    path: 'configuracao-fiscal',
    component: TaxConfiguration
  },
  {
    path: 'pessoas/tecnico',
    component: TechnicianList,
    exactPath: true
  },
  {
    path: 'pessoas/tecnico/editar/:id',
    component: EditTechnician
  },
  {
    path: 'pessoas/tecnico/novo',
    component: EditTechnician
  },
  {
    path: 'categoria-nao-conformidade',
    component: NonComplianceCategoryList,
    exactPath: true
  },
  {
    path: 'categoria-nao-conformidade/editar/:id',
    component: EditNonComplianceCategory,
  },
  {
    path: 'ficha-tecnica',
    component: ProdutDatasheetList,
    exactPath: true,
  },
  {
    path: 'ficha-tecnica/nova',
    component: EditProductDatasheet
  },
  {
    path: 'ficha-tecnica/editar/:id',
    component: EditProductDatasheet
  },
  {
    path: '/referencia-nutricional',
    component: NutritionalReferenceList,
    exactPath: true,
  },
  {
    path: '/referencia-nutricional/editar/:id',
    component: EditNutritionalReference,
  },
  {
    path: '/categoria-nutricional',
    component: NutritionalCategoryList,
    exactPath: true,
  },
  {
    path: '/categoria-nutricional/editar/:id',
    component: EditNutritionalCategory,
  },
  {
    path: '/tipo-cancelamento',
    component: CancellationTypeList,
    exactPath: true,
  },
  {
    path: '/tipo-cancelamento/editar/:id',
    component: EditCancellationType,
  },
  {
    path: '/tipo-cancelamento/nova',
    component: EditCancellationType,
  },
  {
    path: 'lote-economico-producao',
    component: EconomicLotList,
    exactPath: true,
  },
  {
    path: 'lote-economico-producao/editar/:id',
    component: EditEconomicLot
  },
  {
    path: 'lote-economico-producao/novo',
    component: EditEconomicLot
  },
  {
    path: 'lote-ordem-producao',
    component: ProductionOrderBatchList,
    exactPath: true,
  },
  {
    path: 'lote-ordem-producao/nova',
    component: EditProductionOrderBatch
  },
  {
    path: 'lote-ordem-producao/visualizar/:id',
    component: EditProductionOrderBatch
  },
  {
    path: 'cancelamento-apontamento-ordem-producao',
    component: CancelAppontmentList,
    exactPath: true
  },
  {
    path: 'cancelamento-apontamento-ordem-producao/editar/:id',
    component: EditCancelAppontment
  },
  {
    path: 'cancelamento-ordem-producao',
    component: CancelProductionOrderList,
    exactPath: true
  },
  {
    path: 'cancelamento-ordem-producao/editar/:id',
    component: EditCancelProductionOrder
  },
  {
    path: 'calendario-producao',
    exactPath: true,
    component: Calendar
  },
  {
    path: 'credencial',
    exactPath: true,
    component: RpflexCredential
  },
  {
    path: '/host',
    exactPath: true,
    component: Host
  },
  {
    path: 'pendencia-estoque',
    exactPath: true,
    component: StockBacklogList
  },
  {
    path: 'pessoas/setor',
    exactPath: true,
    component: StructureSectorList
  },
  {
    path: 'pessoas/setor/novo',
    component: EditStructureSector
  },
  {
    path: 'pessoas/setor/editar/:id',
    component: EditStructureSector
  },
  {
    path: 'pessoas/departamento',
    exactPath: true,
    component: Department
  },
  {
    path: 'pessoas/departamento/novo',
    component: DepartmentEdit
  },
  {
    path: 'pessoas/departamento/editar/:id',
    component: DepartmentEdit
  },
  {
    path: 'pessoas/estrutura-organizacional',
    exactPath: true,
    component: OrganizationalSructureList
  },
  {
    path: 'pessoas/estrutura-organizacional/editar/:id',
    component: EditOrganizationalSructure
  },
  {
    path: 'pessoas/estrutura-organizacional/novo',
    component: EditOrganizationalSructure
  },
  {
    path: 'finalidade-compra',
    exactPath: true,
    component: FinalidadeCompra
  },
  {
    path: 'finalidade-compra/editar/:id',
    component: FinalidadeCompraEdit
  },
  {
    path: 'finalidade-compra/novo',
    component: FinalidadeCompraEdit
  },
  {
    path: 'centro-resultados',
    exactPath: true,
    component: CentroResultados
  },
  {
    path: 'centro-resultados/novo',
    component: EditCentroResultados
  },
  {
    path: 'centro-resultados/editar/:id',
    component: EditCentroResultados
  },
  {
    path: 'solicitacao-compra',
    exactPath: true,
    component: SolicitacaoCompra
  },
  {
    path: 'solicitacao-compra/novo',
    component: EditSolicitacaoCompra
  },
  {
    path: 'solicitacao-compra/editar/:id',
    component: EditSolicitacaoCompra
  },
  {
    path: 'aprovar-solicitacao-compra',
    exactPath: true,
    component: ApprovePurchaseRequest
  },
  {
    path: 'aprovar-solicitacao-compra/editar/:id',
    component: EditApprovePurchaseRequest
  },
  {
    path: 'cotacao-compras',
    exactPath: true,
    component: PurchaseQuoteList
  },
  {
    path: 'cotacao-compras/nova',
    component: EditPurchaseQuote
  },
  {
    path: 'cotacao-compras/editar/:id',
    component: EditPurchaseQuote
  },
  {
    path: 'pedidos-compra',
    exactPath: true,
    component: PurchaseRequestList
  },
  {
    path: 'pedidos-compra/editar/:id',
    component: CRUDPurchaseRequest
  },
  {
    path: 'pedidos-compra/novo',
    component: CRUDPurchaseRequest
  },
  {
    path: 'orcamento',
    exactPath: true,
    component: Orcamento
  },
  {
    path: 'orcamento/editar/:id',
    component: EditOrcamento
  },
  {
    path: 'orcamento/novo',
    component: EditOrcamento
  },
  {
    path: 'local-entrega',
    exactPath: true,
    component: LocalEntrega
  },
  {
    path: 'local-entrega/novo',
    component: EditLocalEntrega
  },
  {
    path: 'local-entrega/editar/:id',
    component: EditLocalEntrega
  },
  {
    path: 'meta-vendas',
    exactPath: true,
    component: ListingSalesGoals
  },
  {
    path: 'meta-vendas/nova',
    component: CRUDSalesGoals
  },
  {
    path: 'meta-vendas/editar/:id',
    component: CRUDSalesGoals
  },
  {
    path: 'programacao-producao',
    exactPath: true,
    component: ListingProductionSchedule
  },
  {
    path: 'programacao-producao/editar/:id',
    component: CRUDProductionSchedule
  },
  {
    path: 'ciclo-producao',
    component: ListingProductionCycle,
    exactPath: true
  },
  {
    path: 'ciclo-producao/novo',
    component: CRUDProductionCycle
  },
  {
    path: 'ciclo-producao/editar/:id',
    component: CRUDProductionCycle
  },
  {
    path: 'aprovacao-pedido-compra',
    exactPath: true,
    component: ApprovePurchaseOrder
  },
  {
    path: 'aprovacao-pedido-compra/aprovar/:id/*',
    exactPath: true,
    component: CRUDApprovePurchaseOrder
  }
];

export default viewsRoutes;