import { makeStyles } from '@mui/styles'

export const menuLateralMaxWidth = 270

export const menuLateralMinWidth = 54

const useStyles = makeStyles((theme: any) => ({
  menuDrawer: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    width: menuLateralMaxWidth,
    height: '100vh',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    borderRightWidth: 0,
  },
  menuDrawerOpen: {
    width: menuLateralMaxWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuDrawerClose: {
    overflowX: 'hidden',
    width: menuLateralMinWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: 0
    },
  },
  containerLogo: {
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  showImage: {},
  hiddenImage: {},
  logoProjeto: {
    height: 80,
    width: 170,
  },
  iconProjeto: {
    height: 60,
    width: 60,
  },
  containerItens: {
    paddingTop: 0,
    paddingBottom: 0,
    color: `${theme.palette.common.white} !important`,
    overflowX: 'hidden',
  },
  containerItem: {
  },
  itemIcon: {
    color: `${theme.palette.common.white} !important`,
    fontSize: 25,
    minWidth: '38px !important'
  },
  scrollMenu: {
    overflowY: 'auto',
  },
  scrollMenuClosed: {
    overflow: 'hidden',
  },
}))

export { useStyles }
