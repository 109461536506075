import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Utils
import ErrorBoundary from './error/error';
import withAuth from 'root-utils/hocs/with-auth';
import { useStyles } from 'root-views/app.styles';
import { useDispatch } from 'react-redux';
import ContextActions from 'root-states/actions/context-actions';

import viewsRoutes from 'root-views/views-routes';
import MenuLateral from 'root-components/menulateral/menu-lateral';
import MainToolbar from 'root-components/maintoolbar/main-toolbar';
import { Loading } from '@bubotech/sumora-react-components/lib';
import { DispatchAction } from 'root-states/root-dispatcher';
import { Dispatch } from 'redux';
import logoProjeto from 'root-images/logo_projeto.png';
import useLayout from 'root-utils/hooks/use-layout';

export type AppMainLayoutPropType = {};

/**
 * Componente que define o layout principal da aplicação e que chama a validação de login
 *
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @param {AppMainLayoutPropType} props
 */
function AppMainLayout(): JSX.Element {
  const classes = useStyles();

  const history = useNavigate();

  const layout = useLayout();
  const { mainLoading } = layout.reducer;

  const contextActions = new ContextActions(useDispatch<Dispatch<DispatchAction>>());
  useEffect(() => {
    contextActions.changeTab(0);
    contextActions.principalTabHasUnsavedData(false);
    contextActions.setPrincipalFormSubmit(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const LoadingView = () => (
    <div className={classes.cardIconLoading}>
      <img alt='logo' className={classes.iconLoading} src={logoProjeto} />
      Carregando...
    </div>
  );

  return (
    <>
      <div className={classes.main}>
        <MenuLateral />
        <div className={classes.containerToolbarAndRoutes}>
          <Loading show={mainLoading} style={{ zIndex: 100, position: 'absolute' }} />

          <MainToolbar />

          <div className={classes.contentContainer}>
            <Suspense fallback={<LoadingView />}>
              <ErrorBoundary navigate={history}>
                <Routes>
                  {viewsRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={<route.component />} />
                  ))}
                </Routes>
              </ErrorBoundary>
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}

export default withAuth(AppMainLayout);
